<template>
  <transition
    :enterActiveClass="$style.enterActive"
    :enterClass="$style.enter"
    :leaveActiveClass="$style.leaveActive"
    :leaveToClass="$style.leave"
  >
    <div
      v-if="show"
      :class="getAlertClass"
      @click="handleClick"
    >
      <i :class="getAlertIconClass" />
      <div :class="$style.alertText">
        <slot />
        <span
          v-if="!$slots.default"
        >
          {{ text.toString() }}
        </span>
      </div>
      <button
        :class="$style.alertCloseButton"
        @click="hide"
      >
        <i class="jc-actions-x" />
      </button>
    </div>
  </transition>
</template>

<script>
// Do not add new Alert components
// Instead utilize notificationModule

/**
 * @deprecated Use the shared library `@jumpcloud-ap/notifications`
 */
export default {
  name: 'Alert',

  props: {
    delay: { type: Number, default: 0 },
    interactive: { type: Boolean, default: false },
    text: { type: String, default: '' },
    type: { type: String, default: 'info' },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    getAlertClass() {
      return this.$style[`alert${this.getAlertTypeCapitalized()}`];
    },

    getAlertIconClass() {
      const alertTypeCaps = this.getAlertTypeCapitalized();
      let iconClassName = this.$style[`alertIcon${alertTypeCaps}`];
      switch (this.type) {
        case 'success':
          iconClassName += ' jc-actions-check';
          break;
        case 'error':
          iconClassName += ' jc-actions-x';
          break;
        case 'warning':
          iconClassName += ' jc-actions-alert';
          break;
        case 'info':
          iconClassName += ' jc-info-circle';
          break;
        default:
      }
      return iconClassName;
    },
  },

  mounted() {
    this.show = true;

    if (this.delay) {
      window.setTimeout(() => this.hide(), this.delay);
    }
  },

  methods: {
    getAlertTypeCapitalized() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    },

    handleClick() {
      if (!this.interactive) {
        this.show = false;
      }
    },

    hide() {
      this.show = false;
      this.$emit('hidden');
    },
  },
};
</script>

<style module>
.alert {
  background-color: white;
  border-left-style: solid;
  border-left-width: 7px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  max-width: 450px;
  min-width: 320px;
  padding: 25px 50px 25px 25px;
  position: relative;
  right: 20px;
}

.alertSuccess {
  composes: alert;
  border-left-color: var(--jcSuccess);
}

.alertError {
  composes: alert;
  border-left-color: var(--jcError);
}

.alertWarning {
  composes: alert;
  border-left-color: var(--jcWarning);
}

.alertInfo {
  composes: alert;
  border-left-color: var(--jc-color-informational-base);
}

.alertIcon {
  font-size: 26px;
}

.alertIconSuccess {
  composes: alertIcon;
  color: var(--jcSuccess);
}

.alertIconError {
  composes: alertIcon;
  color: var(--jcError);
}

.alertIconWarning {
  composes: alertIcon;
  color: var(--jcWarning);
}

.alertIconInfo {
  composes: alertIcon;
  color: var(--jc-color-informational-base);
}

.alertText {
  flex-grow: 1;
  font-size: 13px;
  font-weight: 400;
  margin-left: 15px;
  margin-top: 4px;
}

.alertCloseButton {
  background-color: transparent;
  border: none;
  color: var(--jcGrey);
  cursor: pointer;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 12px;
  top: 12px;
}

.alertCloseButton i {
  font-size: 18px;
}

.enterActive,
.leaveActive {
  transition: right 0.6s;
}

.enter,
.leave {
  right: -100%;
}
</style>
