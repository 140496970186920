const systemCommandsBody = {
  androidEraseDeviceText: ['Are you sure you want to permanently remove all data from this Android device?', '\n', '**! Erase Device**', 'The device will be reset to factory settings and you’ll no longer manage the device. If you’re troubleshooting an issue, erase the device only after trying other solutions. To manage this device in the future, the end user must re-enroll the device and enter pre-defined security information.'],
  androidEraseDeviceOnSuccess: 'Erase security command sent to device.',
  androidLockDeviceText: ['**Are you sure** you want to lock this lost Android device?', 'The device and/or work profile will remain locked until the user enters the appropriate device/work passcode.'],
  androidLockDeviceTextOnSuccess: 'Lock security command sent to device.',
  androidRebootDeviceText: ['**Are you sure** you want to immediately restart this Android device? Any unsaved work on the device will be lost.'],
  androidRebootDeviceOnSuccess: 'Reboot command sent to device.',
  androidRemoveWorkProfileText: ['Are you sure you want to permanently delete the work profile from this Android device?', '\n', '**! Remove Work Profile**', 'The work profile and all company data, apps, and policies in it will be deleted and you cannot restore it. To use this personal device to access company information in the future, you’ll need to re-enroll the device.'],
  androidRemoveWorkProfileOnSuccess: 'Remove Work Profile security command sent to device.',
};

export default {
  systemCommandsBody,
};
