// Simple singleton to keep track of current MSP state
import { SettingsConsts, XhrConsts } from 'client/util/Constants';

const { isMsp, providerId } = SettingsConsts;
const { xOrgIdKey } = XhrConsts;

export default {
  /**
   * We want to display the standard view:
   *   If this isn't an MSP, or it's an MSP that has launched into an org
   * We want to display the MTP view:
   *   If this is an MSP and the orgId is not set
   */
  get displayMtpView() {
    return this.isAnMsp && !this.orgId;
  },

  get isAnMsp() {
    return window.sessionStorage.getItem(isMsp) === 'true';
  },

  set isAnMsp(value) {
    window.sessionStorage.setItem(isMsp, value);
  },

  get isAnMspOrChild() {
    return window.sessionStorage.getItem('isMspOrChild') === 'true';
  },

  set isAnMspOrChild(value) {
    window.sessionStorage.setItem('isMspOrChild', value);
  },

  get orgId() {
    return window.sessionStorage.getItem(xOrgIdKey) || '';
  },

  set orgId(value) {
    window.sessionStorage.setItem(xOrgIdKey, value);
  },

  get providerId() {
    return window.sessionStorage.getItem(providerId) || '';
  },

  set providerId(value) {
    window.sessionStorage.setItem(providerId, value);
  },

  clearIsAnMsp() {
    window.sessionStorage.removeItem(isMsp);
  },

  clearIsAnMspOrChild() {
    window.sessionStorage.removeItem('isMspOrChild');
  },

  clearOrgId() {
    window.sessionStorage.removeItem(xOrgIdKey);
  },

  clearProviderId() {
    window.sessionStorage.removeItem(providerId);
  },

  clearStorage() {
    this.clearIsAnMsp();
    this.clearIsAnMspOrChild();
    this.clearOrgId();
    this.clearProviderId();
  },
};
