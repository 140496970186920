/**
 * These are the constants for the forms regarding DEP and Certificate setup and renewal
 * used in MdmSetupForm.vue
 * In order to add a new form the FormType should be added and referenced when adding the
 * content to MdmFormsConstants.
 * If any change is needed, make sure it's backwards compatible with what's already been done.
 *
 * The stepNumber property allows us to hide whichever step we want (by skipping it) and still
 * have a coherent numbered list. See the depRenew for an example.
 */
import KbArticleUrls from 'client/util/Constants/KbArticleUrls';

const FormType = {
  certSetup: 'Certificate Setup',
  certRenew: 'Certificate Renew',
  depSetup: 'Automated Device Enrollment Setup',
  depRenew: 'Automated Device Enrollment Renew',
  vppToken: 'Vpp Token Upload',
  vppRenew: 'Vpp Token Renew',
};

const MdmFormsConstants = {
  [FormType.certSetup]: {
    title: {
      header: 'Set Up Apple MDM Certificate',
      body: `Set up Apple MDM to unlock additional services across your organization
      such as remote lock or erase. You can't enroll devices until you upload your MDM push certificate.`,
    },
    steps: {
      download: {
        stepNumber: 1,
        title: 'Download Your CSR',
        text: `Use the Certificate Signing Request (CSR) to establish trust between Apple and your
        organization using JumpCloud's MDM credentials. You need this for the next step.`,
        buttonText: 'Download CSR',
      },
      signIn: {
        stepNumber: 2,
        title: 'Sign into Apple Push Certificate Portal',
        text: 'Use your company\'s Apple ID credentials to sign into Apple Push Certificate Portal and add the certificate for your organization.',
        buttonText: 'Go To Apple',
      },
      appleId: {
        stepNumber: 3,
        title: 'Provide Your Apple ID',
        text: 'Provide your company\'s Apple ID email address that you will use to manage all Apple devices for your organization.',
        buttonText: 'Go To Apple',
      },
      upload: {
        stepNumber: 4,
        title: 'Upload MDM Push Certificate',
        text: `To finish setting up your connection, upload
        **MDM_JumpCloud Inc._Certificate.pem**. You downloaded this file from
        Apple and can find it in your Downloads folder if you saved it to the default
        location.`,
        fileExtensions: '.pem, .crt',
      },
    },
    cta: {
      text: 'Complete Setup',
      notification: 'MDM configuration successfully completed',
    },
  },
  [FormType.certRenew]: {
    title: {
      header: 'Renew MDM Configuration',
      body: `Renew Apple MDM to maintain additional services across your organization such
      as remote lock or erase.`,
    },
    steps: {
      download: {
        stepNumber: 1,
        title: 'Download Your CSR',
        text: `Use the Certificate Signing Request (CSR) to establish trust between Apple and your
        organization using JumpCloud's MDM credentials. You need this for the next step.`,
        buttonText: 'Download CSR',
      },
      signIn: {
        stepNumber: 2,
        title: 'Sign into Apple Push Certificate Portal',
        text: 'Use your company\'s Apple ID credentials to sign into Apple Push Certificate Portal and add the certificate for your organization.',
        buttonText: 'Go To Apple',
      },
      appleId: {
        stepNumber: 3,
        title: 'Provide Your Apple ID',
        text: 'Provide your company\'s Apple ID email address that you will use to manage all Apple devices for your organization.',
        buttonText: 'Go To Apple',
      },
      upload: {
        stepNumber: 4,
        title: 'Upload MDM Push Certificate',
        text: `To finish renewing up your connection, upload
        **MDM_JumpCloud Inc._Certificate.pem**. You downloaded this file from
        Apple and can find it in your Downloads folder if you saved it to the default
        location.`,
        fileExtensions: '.pem, .crt',
      },
    },
    cta: {
      text: 'Complete Renewal',
      notification: 'MDM configuration successfully renewed',
    },
  },
  [FormType.depSetup]: {
    title: {
      header: 'Set Up Apple’s Automated Device Enrollment',
      body: `Set up Apple’s Automated Device Enrollment server token to buy devices and
      have them automatically enrolled in your organization’s MDM.`,
    },
    steps: {
      download: {
        stepNumber: 1,
        title: 'Generate a Key',
        text: `Use this key to obtain a server token that lets JumpCloud securely connect to the
        Automated Device Enrollment web service. You need this key for the next step.`,
        buttonText: 'download',
      },
      signIn: {
        stepNumber: 2,
        title: 'Sign in to Apple',
        text: 'Use your company\'s Apple ID credentials to [sign in to Apple Business Manager](https://business.apple.com/) and go to settings to select the server you want to renew a token for. If you have an education account [sign in to Apple School Manager.](https://school.apple.com/)',
      },
      upload: {
        stepNumber: 3,
        title: 'Upload Automated Device Enrollment Token',
        text: `To finish setting up your connection, upload the server token for your MDM server.
        You downloaded this file from Apple Business Manager and can find it in your
        Downloads folder if you saved it to the default location.`,
        fileExtensions: '.p7m',
      },
    },
    cta: {
      text: 'Complete Setup',
      notification: 'Automated Device Enrollment setup completed successfully!',
    },
  },
  [FormType.depRenew]: {
    title: {
      header: 'Renew Apple’s Automated Device Enrollment',
      body: 'Renew Apple’s Automated Device Enrollment server token to continue enrolling devices into JumpCloud\'s MDM through Automated Device Enrollment ',
    },
    steps: {
      signIn: {
        stepNumber: 1,
        title: 'Sign in to Apple',
        text: 'Use your company\'s Apple ID credentials to [sign in to Apple Business Manager](https://business.apple.com/) and go to settings to select the server you want to renew a token for. If you have an education account [sign in to Apple School Manager.](https://school.apple.com/)',
      },
      upload: {
        stepNumber: 2,
        title: 'Upload Automated Device Enrollment Token',
        text: `Locate the MDM Server registered to this JumpCloud organization in Apple Business
          Manager. Download the Automated Device Enrollment Token from this MDM Server and upload it here to renew your
          Automated Device Enrollment token`,
        fileExtensions: '.p7m',
      },
    },
    cta: {
      text: 'Complete',
      notification: 'Automated Device Enrollment renewal completed successfully!',
    },
  },
  [FormType.vppToken]: {
    title: {
      header: 'Configure Apple’s VPP',
      body: 'Set up Apple\'s Volume Purchase Program (VPP) to purchase app licenses in bulk from the macOS app store and apply them to your devices.',
    },
    steps: {
      signIn: {
        stepNumber: 1,
        title: 'Sign in to Apple',
        text: 'Use your company\'s Apple ID credentials to [sign in to Apple Business Manager](https://business.apple.com/) and go to settings to select the server you want to download a token for. If you have an education account [sign in to Apple School Manager.](https://school.apple.com/)',
      },
      upload: {
        stepNumber: 2,
        title: 'Upload the token to JumpCloud',
        text: 'Download your location\'s token from Apple Business Manager and upload it here to connect your VPP account.',
        fileExtensions: '.vpptoken',
      },
    },
    cta: {
      text: 'Complete',
      notification: 'VPP token uploaded successfully!',
    },
  },
  [FormType.vppRenew]: {
    title: {
      header: 'Renew Apple’s VPP',
      body: 'Add or Renew your token to continue purchasing bulk app licenses by signing in to [Apple Business Manager](https://business.apple.com/) and navigating to Payments and Billing to download your site\'s server token. ',
      link: {
        href: KbArticleUrls.mdmVpp,
        text: 'Learn how',
      },
    },
    steps: {
      upload: {
        stepNumber: -1,
        title: 'Upload server token to JumpCloud',
        text: 'Upload the token you downloaded from the Apple Business Manager to renew your VPP account. Your token is valid for one year.',
        fileExtensions: '.vpptoken',
      },
    },
    cta: {
      text: 'Complete',
      notification: 'VPP token renewed successfully!',
    },
  },
};

export default {
  FormType,
  MdmFormsConstants,
};
