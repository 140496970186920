const kbFields = {
  articleTotalViewCount: 'articleTotalViewCount',
  id: 'id',
  isWhatsNew: 'isWhatsNew',
  body: 'body',
  publishedDate: 'publishedDate',
  title: 'title',
  url: 'url',
  whatsNewBlurb: 'whatsNewBlurb',
  whatsNewTitle: 'whatsNewTitle',
};

export default { kbFields };
