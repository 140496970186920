const APIPaths = {
  users: '/api/v2/users/',
};

const NoUserWidgetText = {
  text: 'There\'s nothing here yet! Add users to JumpCloud to access User Notifications',
  title: 'User Notifications',
  buttonText: 'Add User',
};

export default {
  APIPaths,
  NoUserWidgetText,
};
