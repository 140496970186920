import Alert from 'client/components/Alert/Alert.vue';
import Vue from 'vue';

/**
 * @deprecated
 */
export default function renderAlertComponent(el, props) {
  return new Vue({
    el,
    name: 'AlertWrapper',
    render: h => h(Alert, {
      props,
    }),
  });
}
