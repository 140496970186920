import ConfigurationCategoriesConst from 'client/util/Constants/ConfigurationCategoriesConstants';

const zeroStateTemplate = {
  darwin: [
    {
      name: 'macOS Vanguard',
      osMinorUpdateGracePeriod: {
        checkbox: true,
        delayDays: '3',
      },
    },
    {
      name: 'macOS Early Adoption',
      osMinorUpdateGracePeriod: {
        checkbox: true,
        delayDays: '7',
      },
      osMinorUpdatesDeferral: {
        checkbox: true,
        delayDays: '7',
      },
    },
    {
      name: 'macOS General Adoption',
      osMinorUpdateGracePeriod: {
        checkbox: true,
        delayDays: '10',
      },
      osMinorUpdatesDeferral: {
        checkbox: true,
        delayDays: '15',
      },
    },
    {
      name: 'macOS Late Adoption',
      osMinorUpdateGracePeriod: {
        checkbox: true,
        delayDays: '10',
      },
      osMinorUpdatesDeferral: {
        checkbox: true,
        delayDays: '30',
      },
      osMajorUpdates: {
        checkbox: false,
        delayDays: '90',
      },
      nonOsUpdates: {
        checkbox: false,
        delayDays: '30',
      },
    },
  ],
  linux: [
    {
      name: 'Linux (Ubuntu) Vanguard',
      osReleaseUpgradeDeferral: {
        ltsOnly: false,
        delayDays: '0',
      },
    },
    {
      name: 'Linux (Ubuntu) Early Adoption',
      osReleaseUpgradeDeferral: {
        ltsOnly: false,
        delayDays: '3',
      },
    },
    {
      name: 'Linux (Ubuntu) General Adoption',
      osReleaseUpgradeDeferral: {
        ltsOnly: true,
        delayDays: '7',
      },
    },
    {
      name: 'Linux (Ubuntu) Late Adoption',
      osReleaseUpgradeDeferral: {
        ltsOnly: true,
        delayDays: '14',
      },
    },
  ],
  windows: [
    {
      name: 'Windows Vanguard',
      automaticInstallMinorUpdates: {
        checkbox: true,
      },
      automaticInstallUpdates: {
        checkbox: true,
      },
      automaticaUpdates: {
        select: 3,
      },
      daysOfWeekToInstall: {
        select: 0,
      },
      timeOfDayToInstall: {
        select: 0,
      },
      installUpdatesFrequency: {
        select: 'ScheduledInstallEveryWeek',
      },
      automaticaUpdatesDetectionFrequency: {
        checkbox: true,
      },
      hours: {
        select: 1,
      },
      installUpdatesDuringMaintenance: {
        checkbox: true,
      },
      removePauseUpdatesFeature: {
        checkbox: true,
      },
      enableWindowsUpdatePowerManagement: {
        checkbox: true,
      },
      allowNonAdminsToReceivesUpdateNotifications: {
        checkbox: true,
      },
      displayUpdateNotificationOptions: {
        checkbox: true,
      },
      selectUpdatedNotificationOptions: {
        checkbox: true,
      },
      deferUpdates: {
        checkbox: true,
      },
      daysToDeferUpdates: {
        delayDays: 0,
      },
      deferFeaturesUpgrades: {
        checkbox: true,
      },
      daysToDeferFeaturesUpgrades: {
        delayDays: 0,
      },
      upgradeChannel: {
        select: 32,
      },
      deadlinesForAutoUpdates: {
        checkbox: true,
      },
      qualityUpdateGracePeriod: {
        select: 1,
      },
      qualityRestartGracePeriod: {
        select: 2,
      },
      updateInstallationGracePeriod: {
        select: 1,
      },
      restartGracePeriod: {
        select: 2,
      },
    },
    {
      name: 'Windows Early Adoption',
      automaticInstallMinorUpdates: {
        checkbox: true,
      },
      automaticInstallUpdates: {
        checkbox: true,
      },
      automaticaUpdates: {
        select: 3,
      },
      daysOfWeekToInstall: {
        select: 0,
      },
      timeOfDayToInstall: {
        select: 0,
      },
      installUpdatesFrequency: {
        select: 'ScheduledInstallEveryWeek',
      },
      automaticaUpdatesDetectionFrequency: {
        checkbox: true,
      },
      hours: {
        select: 1,
      },
      installUpdatesDuringMaintenance: {
        checkbox: true,
      },
      removePauseUpdatesFeature: {
        checkbox: true,
      },
      enableWindowsUpdatePowerManagement: {
        checkbox: true,
      },
      allowNonAdminsToReceivesUpdateNotifications: {
        checkbox: true,
      },
      displayUpdateNotificationOptions: {
        checkbox: true,
      },
      selectUpdatedNotificationOptions: {
        checkbox: true,
      },
      deferUpdates: {
        checkbox: true,
      },
      daysToDeferUpdates: {
        delayDays: 7,
      },
      deferFeaturesUpgrades: {
        checkbox: true,
      },
      daysToDeferFeaturesUpgrades: {
        delayDays: 7,
      },
      upgradeChannel: {
        select: 32,
      },
      deadlinesForAutoUpdates: {
        checkbox: true,
      },
      qualityUpdateGracePeriod: {
        select: 5,
      },
      qualityRestartGracePeriod: {
        select: 2,
      },
      updateInstallationGracePeriod: {
        select: 5,
      },
      restartGracePeriod: {
        select: 2,
      },
    },
    {
      name: 'Windows General Adoption',
      automaticInstallMinorUpdates: {
        checkbox: true,
      },
      automaticInstallUpdates: {
        checkbox: true,
      },
      automaticaUpdates: {
        select: 3,
      },
      daysOfWeekToInstall: {
        select: 0,
      },
      timeOfDayToInstall: {
        select: 0,
      },
      installUpdatesFrequency: {
        select: 'ScheduledInstallEveryWeek',
      },
      automaticaUpdatesDetectionFrequency: {
        checkbox: true,
      },
      hours: {
        select: 1,
      },
      installUpdatesDuringMaintenance: {
        checkbox: true,
      },
      removePauseUpdatesFeature: {
        checkbox: true,
      },
      enableWindowsUpdatePowerManagement: {
        checkbox: true,
      },
      allowNonAdminsToReceivesUpdateNotifications: {
        checkbox: true,
      },
      displayUpdateNotificationOptions: {
        checkbox: true,
      },
      selectUpdatedNotificationOptions: {
        checkbox: true,
      },
      deferUpdates: {
        checkbox: true,
      },
      daysToDeferUpdates: {
        delayDays: 15,
      },
      deferFeaturesUpgrades: {
        checkbox: true,
      },
      daysToDeferFeaturesUpgrades: {
        delayDays: 15,
      },
      upgradeChannel: {
        select: 32,
      },
      deadlinesForAutoUpdates: {
        checkbox: true,
      },
      qualityUpdateGracePeriod: {
        select: 8,
      },
      qualityRestartGracePeriod: {
        select: 2,
      },
      updateInstallationGracePeriod: {
        select: 8,
      },
      restartGracePeriod: {
        select: 2,
      },
    },
    {
      name: 'Windows Late Adoption',
      automaticInstallMinorUpdates: {
        checkbox: true,
      },
      automaticInstallUpdates: {
        checkbox: true,
      },
      automaticaUpdates: {
        select: 3,
      },
      daysOfWeekToInstall: {
        select: 0,
      },
      timeOfDayToInstall: {
        select: 0,
      },
      installUpdatesFrequency: {
        select: 'ScheduledInstallEveryWeek',
      },
      automaticaUpdatesDetectionFrequency: {
        checkbox: true,
      },
      hours: {
        select: 1,
      },
      installUpdatesDuringMaintenance: {
        checkbox: true,
      },
      removePauseUpdatesFeature: {
        checkbox: true,
      },
      enableWindowsUpdatePowerManagement: {
        checkbox: true,
      },
      allowNonAdminsToReceivesUpdateNotifications: {
        checkbox: true,
      },
      displayUpdateNotificationOptions: {
        checkbox: true,
      },
      selectUpdatedNotificationOptions: {
        checkbox: true,
      },
      deferUpdates: {
        checkbox: true,
      },
      daysToDeferUpdates: {
        delayDays: 30,
      },
      deferFeaturesUpgrades: {
        checkbox: true,
      },
      daysToDeferFeaturesUpgrades: {
        delayDays: 30,
      },
      upgradeChannel: {
        select: 32,
      },
      deadlinesForAutoUpdates: {
        checkbox: true,
      },
      qualityUpdateGracePeriod: {
        select: 8,
      },
      qualityRestartGracePeriod: {
        select: 2,
      },
      updateInstallationGracePeriod: {
        select: 8,
      },
      restartGracePeriod: {
        select: 2,
      },
    },
  ],
};

const zeroStateBrowserPatchTemplate = {
  chrome: [
    {
      name: 'Chrome Day Zero',
      enforceAutomaticUpdates: {
        value: true,
      },
      ComponentUpdatesEnabled: {
        value: true,
      },
      RelaunchNotification: {
        value: 2,
      },
      RelaunchNotificationPeriod: {
        value: 86400000,
      },
    },
    {
      name: 'Chrome Early Adoption Ring',
      enforceAutomaticUpdates: {
        value: true,
      },
      ComponentUpdatesEnabled: {
        value: true,
      },
      RelaunchNotification: {
        value: 2,
      },
      RelaunchNotificationPeriod: {
        value: 259200000,
      },
    },
    {
      name: 'Chrome General Adoption Ring',
      enforceAutomaticUpdates: {
        value: true,
      },
      ComponentUpdatesEnabled: {
        value: true,
      },
      RelaunchNotification: {
        value: 2,
      },
      RelaunchNotificationPeriod: {
        value: 604800000,
      },
    },
    {
      name: 'Chrome Late Adoption Ring',
      enforceAutomaticUpdates: {
        value: true,
      },
      ComponentUpdatesEnabled: {
        value: true,
      },
      RelaunchNotification: {
        value: 2,
      },
      RelaunchNotificationPeriod: {
        value: 1209600000,
      },
    },
  ],
};

const policiesUpdatesConfigFields = {
  darwin: {
    osMinorUpdateGracePeriod: {
      checkbox: 'EnableMinorUpdateInstallGracePeriod',
      delayDays: 'MinorUpdateInstallGracePeriod',
    },
    osMinorUpdatesDeferral: {
      checkbox: 'EnableDeferMinorOSUpdateReleases',
      delayDays: 'MinorUpdateDeferralDays',
    },
    osMajorUpdates: {
      checkbox: 'EnableDeferMajorOSUpdateReleases',
      delayDays: 'MajorUpdateDelayDays',
    },
    nonOsUpdates: {
      checkbox: 'EnableDeferNonOSUpdateReleases',
      delayDays: 'NonOSUpdateDelayDays',
    },
  },
  linux: {
    osReleaseUpgradeDeferral: {
      delayDays: 'delay_days',
      ltsOnly: 'lts_only',
    },
  },
  windows: {
    automaticInstallMinorUpdates: {
      checkbox: 'AUTO_INSTALL_MINOR_UPDATES_OSP',
    },
    automaticInstallUpdates: {
      checkbox: 'AUTO_INSTALL_UPDATES_OSP',
    },
    automaticaUpdates: {
      select: 'AUTO_UPDATES_MODE_OSP',
    },
    daysOfWeekToInstall: {
      select: 'AUTO_INSTALL_DAY_OSP',
    },
    timeOfDayToInstall: {
      select: 'AUTO_UPDATES_TIME_OSP',
    },
    installUpdatesFrequency: {
      select: 'AUTO_INSTALL_SCHEDULE_OSP',
    },
    automaticaUpdatesDetectionFrequency: {
      checkbox: 'ENABLE_AUTO_UPDATES_DETECTION_OSP',
    },
    hours: {
      select: 'AUTO_UPDATES_DETECTION_FREQUENCY_OSP',
    },
    installUpdatesDuringMaintenance: {
      checkbox: 'AUTO_INSTALL_DURING_MAINT_OSP',
    },
    removePauseUpdatesFeature: {
      checkbox: 'REMOVE_PAUSE_UPDATES_ACCESS_OSP',
    },
    enableWindowsUpdatePowerManagement: {
      checkbox: 'ENABLE_WINDOWS_UPDATE_POWER_MANAGEMENT_OSP',
    },
    allowNonAdminsToReceivesUpdateNotifications: {
      checkbox: 'RESTART_NON_ADMIN_NOTIFICATIONS_OSP',
    },
    displayUpdateNotificationOptions: {
      checkbox: 'DISPLAY_UPDATE_NOTIFICATIONS_OSP',
    },
    selectUpdatedNotificationOptions: {
      select: 'RESTART_NOTIFICATION_OPTION_OSP',
    },
    deferUpdates: {
      checkbox: 'DEFER_UPDATES_OSP',
    },
    daysToDeferUpdates: {
      delayDays: 'DEFER_UPDATE_PERIOD_OSP',
    },
    deferFeaturesUpgrades: {
      checkbox: 'DEFER_UPGRADES_OSP',
    },
    daysToDeferFeaturesUpgrades: {
      delayDays: 'DEFER_UPGRADE_PERIOD_OSP',
    },
    upgradeChannel: {
      select: 'DEFER_UPGRADE_CHANNEL_OSP',
    },
    deadlinesForAutoUpdates: {
      checkbox: 'SPECIFY_AUTO_DEADLINES_OSP',
    },
    qualityUpdateGracePeriod: {
      select: 'SPECIFY_AUTO_DEADLINES_QUALITY_INSTALL_GRACE_PERIOD_OSP',
    },
    qualityRestartGracePeriod: {
      select: 'SPECIFY_AUTO_DEADLINES_QUALITY_RESTART_GRACE_PERIOD_OSP',
    },
    updateInstallationGracePeriod: {
      select: 'SPECIFY_AUTO_DEADLINES_FEATURE_INSTALL_GRACE_PERIOD_OSP',
    },
    restartGracePeriod: {
      select: 'SPECIFY_AUTO_DEADLINES_FEATURE_RESTART_GRACE_PERIOD_OSP',
    },
  },
};

const ospPolicyNameToOs = {
  [ConfigurationCategoriesConst.windows.configureOspWindowsUpdates.name]: 'windows',
  [ConfigurationCategoriesConst.darwin.automaticMacOsUpdates.name]: 'darwin',
  [ConfigurationCategoriesConst.linux.automaticUbuntuUpdates.name]: 'linux',
};

const browserPolicyNameToBrowser = {
  [ConfigurationCategoriesConst.universal.chromeUniversal.name]: 'chrome',
};

const ospPolicyNames = Object.keys(ospPolicyNameToOs).sort();
const browserPolicyNames = Object.keys(browserPolicyNameToBrowser).sort();

export {
  policiesUpdatesConfigFields,
  zeroStateTemplate,
  zeroStateBrowserPatchTemplate,
  ospPolicyNameToOs,
  ospPolicyNames,
  browserPolicyNames,
  browserPolicyNameToBrowser,
};
