export default {
  administrators: 'administrators',
  administratorsReadonly: 'administrators.readonly',
  administratorsSelf: 'administrators.self',
  applications: 'applications',
  applicationsReadonly: 'applications.readonly',
  applicationstemplates: 'applicationtemplates',
  applicationstemplatesReadonly: 'applicationtemplates.readonly',
  authn: 'authn',
  authnReadonly: 'authn.readonly',
  billing: 'billing',
  commandrunnerLegacy: 'commandrunner.legacy',
  commands: 'commands',
  commandsReadonly: 'commands.readonly',
  commandsSchedule: 'commands.schedule',
  directories: 'directories',
  directoriesReadonly: 'directories.readonly',
  directoriesinsightsReadonly: 'directoryinsights.readonly',
  eventsReadonly: 'events.readonly',
  groups: 'groups',
  groupsReadonly: 'groups.readonly',
  jobs: 'jobs',
  jobsReadonly: 'jobs.readonly',
  mdm: 'mdm',
  mdmReadonly: 'mdm.readonly',
  mfa: 'mfa',
  mfaReadonly: 'mfa.readonly',
  notifications: 'notifications',
  notificationsReadonly: 'notifications.readonly',
  organizations: 'organizations',
  organizationsReadonly: 'organizations.readonly',
  providers: 'providers',
  providersReadonly: 'providers.readonly',
  radius: 'radius',
  radiusReadonly: 'radius.readonly',
  remoteAssist: 'remoteassist',
  remoteAssistSessions: 'remoteassist.sessions',
  roles: 'roles',
  rolesReadonly: 'roles.readonly',
  scim: 'scim',
  settingsReadonly: 'settings.readonly',
  support: 'support',
  systeminsights: 'systeminsightsReadonly',
  systems: 'systems',
  systemsReadonly: 'systems.readonly',
  userportal: 'userportal',
  userportalReadonly: 'userportal.readonly',
  users: 'users',
  usersReadonly: 'users.readonly',
};
