const customEmails = {
  lockoutNotice: 'lockout_notice',
  passwordExpiration: 'password_expiration',
  passwordExpirationWarning: 'password_expiration_warning',
  passwordResetConfirmation: 'password_reset_confirmation',
  userChangePassword: 'user_change_password',
};

const customEmailDescriptions = {
  emailDisclaimer: 'Disclaimer footer that displays on all emails, not just custom emails. You can update it on the settings page.',
};

export default {
  customEmails,
  customEmailDescriptions,
};
