const androidEmmDeleteAppsText = {
  bodyTitle: 'Are you sure you want to delete',
  moreInfo: 'For more information, visit [Software Management: Android](https://jumpcloud.my.site.com/support/s/article/Users-Enroll-Your-Personal-Android-Device).',
  notificationTitle: 'App Deletion',
  resultInRemoval: 'This will result in removal of all app data and associations, including associations with devices and users.',
  title: 'Delete Application',
  toProceed: 'To proceed, enter the number of applications that will be deleted.',
};

const androidEmmDeviceDetailsText = {
  androidDeviceName: 'Android Device Name',
  description: 'Description',
  deviceDetails: 'Android Device Details',
  jcDeviceName: 'JumpCloud Device Name',
  jcSystemID: 'JumpCloud System ID',
  lastContact: 'Last Contact',
};

const androidEmmRegistrationLabel = {
  enterprises: 'Organization Name',
  clientID: 'Organization ID',
  companyEmailAddress: 'Company Email Address',
  dateAndTime: 'Registration Date',
  status: 'Status',
};

const androidEmmRegistrationText = {
  title: 'Android EMM Registration',
  label: androidEmmRegistrationLabel,
};

const androidEmmUserConfigText = {
  checkBoxText: 'Allow users to enroll mobile devices and access **Enroll your Android Device** in the User Portal.',
  text: 'You can control if an end user can enroll Android devices into JumpCloud\'s Android EMM to access company resources. Select Allow users to enroll mobile devices, and instruct the user to log into the User Portal and go to Security > Enroll Your Android Device. After the user enrolls the device into Android EMM, the device is visible in the Admin Portal.',
  configBodyText: 'Allow users to enroll personal mobile devices in JumpCloud\'s Android EMM so ,'
    + '      that they can access company resources, such as email calendar, contacts and documents.'
    + '      Select the field below and instruct the user to log into the User Portal and go to'
    + '      **Security > Enroll Your Android Device**. After the user scans a QR code to enroll the phone '
    + '      into Android EMM the enrolled Android device is visible in the Admin Portal.',
  configBodyTooltip: 'Enrolling a personal Android device in EMM lets the user securely access'
    + '      company information on a separate, encrypted work profile on the device.'
    + '      After the device is enrolled, you can view and manage the device in'
    + '      DEVICE MANAGEMENT > Devices. See [Add and Manage Android Devices](https://jumpcloud.my.site.com/support/s/article/Add-and-Manage-Android-Devices)\n'
    + '      and [Users: Enroll Your Personal Android Device.](https://jumpcloud.my.site.com/support/s/article/Users-Enroll-Your-Personal-Android-Device)',
  title: 'User Android Configuration',
};

const androidEmmAdminConfigText = {
  text: 'Enroll a company-owned Android device into JumpCloud\'s Android EMM by clicking **View QR Code** and following the prompts. The enrolled Android device is visible in the Admin Portal. Deliver the device to the end user, and the end user can immediately access company resources, such as email, calendar, contacts and documents.',
  warning: 'This QR Code should **_only_** be used to enroll company-owned devices. Personal devices should **_only_** be enrolled via User Portal.',
  buttonText: 'View QR Code',
  configBodyText: 'Enroll a company-owned Android device in JumpCloud’s Android EMM by clicking **View QR Code** and following the '
    + '      prompts to enroll the device into Android EMM. The enrolled Android device is visible in the Admin portal. '
    + '      Deliver the phone to the user and the user can immediately access company resources, such as email, calendar, contacts and documents.',
  configBodyTooltip: 'Enrolling a mixed-use company-owned Android device in EMM is a secure way to manage'
    + '      the device and its data. An encrypted work profile keeps personal data separate'
    + '      from corporate apps, data, and settings. After the device is enrolled, you can'
    + '      view and manage the device in DEVICE MANAGEMENT > Devices. See '
    + '      [Add and Manage Android Devices](https://jumpcloud.my.site.com/support/s/article/Add-and-Manage-Android-Devices).',
  title: 'Admin Android Configuration',
};

const androidEmmZeroTouchAdminConfigText = {
  text: 'Remotely deploy new corporate devices in a preconfigured, managed state out of the box, '
    + 'with the end user bound to them, Use the device policy controllers (DPC) below in your '
    + 'zero-touch enrollment configurations. [Learn More](https://jumpcloud.com/support/configure-zero-touch-enrollment-for-android)',
  buttonText: 'Launch Zero-Touch Portal',
  tooltipText: 'Devices must be purchased from an [authorized reseller.](https://androidenterprisepartners.withgoogle.com/resellers/)',
  title: 'Android Zero-Touch Enrollment',
  signInto: 'Sign into the Zero-Touch enrollment portal with your Google account.',
  selectOption: 'Select the option to add a new configuration.',
  fillInformation: 'Fill out the information in the configuration panel.',
  selectPolicy: 'Select **Android Device Policy** in the EMM DPC extras field.',
  copyJson: 'Copy one of the following JSON texts below and paste it into the DPC extra field in the Zero-Touch Portal.',
  workProfile: 'Work Profile for Company-Owned Devices',
  fullyManaged: 'Fully Managed Devices',
  dedicated: 'Dedicated Devices',
};

const enrollmentFirstCard = {
  title: 'Android EMM',
  text: '**JumpCloud Android EMM** enables you to seamlessly consolidate your Android device management (both desktops and mobility) in order to reduce time, costs, and resources',
};

const enrollmentSecondCard = {
  title: 'Key Features',
  text: ['Device Provisioning', 'Device Security', 'Application Management', 'Device Management', 'Account Management'],
};

const enrollmentThirdCard = {
  title: 'Key Benefits',
  text: ['Minimized Security Risks', 'Enhanced End User Privacy', 'Decreased IT Administration', 'Increased Productivity, and Lower User Friction'],
};

const enrollmentInstructions = {
  startClickButton: 'Click **Begin Registration**.',
  signInGoogleAccount: 'Enter your Google account, then click **Save**.',
  signInGoogleNote: 'NOTE: This account can be a standard Gmail account but Google recommends that you create a new Google account.',
  enterBusinessName: 'Enter your organization\'s name, then click **Next**.',
  enterContactDetails: 'Enter contact details for your organization, select **I have read and agree to the Managed Google Play agreement**, then click **Confirm**.',
  completeRegistration: 'Click **Complete Registration** to finish and return to the Admin Portal.',
};

const androidEmmEnrollmentText = {
  title: 'Welcome to Android EMM!',
  text: 'To begin the registration process, you will need to complete the following steps:',
  enrollmentfirstCard: enrollmentFirstCard,
  enrollmentsecondCard: enrollmentSecondCard,
  enrollmentthirdCard: enrollmentThirdCard,
  instruction: enrollmentInstructions,
  bottonText: 'Begin Enrollment',
};

const androidEmmDeleteRegistrationText = {
  title: 'Delete Registration',
  text: 'Are you sure you want to delete your Android EMM Registration?',
  alert: 'Irreversible Action',
  warningMessage: 'Deleting your registration cannot be undone. If you choose to proceed the following will occur, and you will need to re-enroll from the starting point.',
  enrollDeviceUnenrolled: 'Your enrolled devices will be un-enrolled (wiped and/or factory reset).',
  createdPoliciesPurged: 'Your created policies will be purged.',
  addedApplicationRemoved: 'Your added applications will be removed.',
  moreInfo: 'You must unlink your zero-touch account from this enterprise instance in the Android zero-touch portal before deleting the registration. If you don\'t, your Android zero-touch account will be unrecoverable.',
};

const androidEmmSoftwareApps = {
  appURL: 'App URL',
  description: 'Description',
  details: 'Details',
  displayMode: 'Display Mode',
  installMode: 'Install Mode',
  offeredBy: 'Offered by',
  requiresAndroid: 'Requires Android',
  runtimePermissions: 'Runtime Permissions',
  type: 'Type',
  updateMode: 'Update Mode',
  updatedOn: 'Updated on',
  version: 'Version',
};

const androidEmmQrEnrollmentText = {
  title: 'Enroll Your Company-Owned Android Device',
  workProfile: '**Work Profile for Company-Owned Device**',
  deviceVersion: '**Android 8.0 or later devices only**',
  setupDeviceTitle: 'Setting up a **company-owned device** with a\n'
    + '                **work profile** enables the device for both work and\n'
    + '                personal use. On company-owned devices with work profiles:',
  setupDeviceInfo: [
    'Most app, data, and other management policies apply to the work profile only.',
    'The employee\'s personal profile remains private. However, enterprises can enforce certain device-wide policies and personal usage policies.',
    'Enterprises can enforce compliance actions on an entire device or only its work profile.',
    'Device deletion and other commands may apply to an entire device.',
  ],
  workProfileInstructionTitle: 'To set up a **company-owned device** with a **work profile** follow the following instructions:',
  workProfileInstructionSteps: [
    'On a new or factory-reset device, tap the screen six times in the same spot. This triggers the device to prompt you to scan a QR code.',
    'Scan the QR code to start the setup. \n&nbsp;&nbsp;&nbsp;Or\n Enter the enrollment token manually to start the setup.',
    'Follow the on-screen instructions to complete the enrollment.',
  ],
  scanToEnroll: 'Click to enlarge & scan QR code',
  linkText: 'Direct Link:',
  titleFullyManaged: 'Enroll Your Company-Owned Android Device',
  setupDeviceInfoFullyManaged: [
    'On a new or factory-reset device, tap the screen six times in the same spot. This triggers a prompt to scan the QR code.',
    'Use the device\'s camera to scan the QR code in the Admin portal.',
    'Follow on-screen instructions to complete the enrollment.',
  ],
  workprofile: '**Work Profile for Company-Owned Device**',
  workProfileInstruction: 'A work profile on the company device must run Android 8.0 or later.',
  fullyManaged: '**Fully Managed Device**',
  fullyManagedDeviceInstruction: 'A fully managed device must run Android 5.1 or later.',
  dedicatedDevice: '**Dedicated Device**',
  dedicatedDeviceDeviceInstruction: 'A dedicated device must run Android 5.1 or later.',
};

const creatAndroidDeviceText = {
  byodTitle: 'Personal Android Devices',
  byodBody: androidEmmUserConfigText,
  selectLabel: 'Select the Device Group to which enrolled devices will be automatically added:',
  selectText: 'None',
  copeTitle: 'Company-Owned Android Devices',
  copeBody: androidEmmAdminConfigText,
};

const androidEnterprisePreEnrollmentText = {
  title: 'Configure Android EMM',
  header: 'android',
  buttonText: 'Let\'s Do It!',
  getYourSetupText: 'Let\'s get you set up! Here you can manage any apps related to Android devices. You can add and assign public, private, and web applications!',
};

const addAndroidAppText = {
  assign: 'and assign public, private and web applications!',
  clickToAddNew: 'Click "Add New" above to add new software to Android',
  getYourSetup: 'Let\'s get you set up! Here you can manage any apps',
  manageDevice: 'devices, and manage here.',
  releatedTo: 'related to Android devices. You can add',
  title: 'Add your first Android app!',
  welcomeText: 'Welcome!',
};

const applicationInstallMode = {
  availableTitle: 'Available',
  availableValue: 'AVAILABLE',
  installTitle: 'Force Install',
  installValue: 'FORCE_INSTALLED',
  blockTitle: 'Block',
  blockValue: 'BLOCKED',
};

const runtimePermissions = {
  allowTittle: 'Allow',
  allowValue: 'GRANT',
  promptTitle: 'Prompt',
  promptValue: 'PROMPT',
  denyTitle: 'Deny',
  denyValue: 'DENY',
};

const applicationUpdateMode = {
  defaultTitle: 'Default',
  defaultValue: 'AUTO_UPDATE_DEFAULT',
  priorityTitle: 'High Priority',
  priorityValue: 'AUTO_UPDATE_HIGH_PRIORITY',
  postponeTitle: 'Postpone',
  postponeValue: 'AUTO_UPDATE_POSTPONED',
};

const googleAppsDetailsTooltip = {
  applicationInstallModeTooltip: `Choose how to install this app:
        • Available - The app is available to install.
        • Force Install - The app is automatically installed and can’t be removed by the user.
        • Block - The app is blocked and can’t be installed.`,
  applicationUpdateModeTooltip: `Choose when automatic app updates are applied:
        • Default - The app is automatically updated.
        • High Priority - The app is updated as soon as possible.
        • Postpone - The app is automatically updated after 90 days with low priority.`,
  runtimePermissionsTooltip: `Choose the permissions for a work app:
        • Allow - Automatically grant permission.
        • Prompt - Users choose whether to grant permission.
        • Deny - Automatically deny permission.`,
};

const googleAppsDetailsText = {
  applicationInstallMode,
  applicationUpdateMode,
  runtimePermissions,
  tooltipText: googleAppsDetailsTooltip,
};

const enrollmentTypesText = {
  heading: 'Supported Enrollment Types',
};

const enrollmentTokenText = {
  title: 'Enrollment Tokens',
};

const androidEmmDeleteTokensText = {
  bodyTitle: 'Are you sure you want to delete',
  moreInfo: 'For more information, visit [MDM: Android](https://jumpcloud.my.site.com/support/s/article/Users-Enroll-Your-Personal-Android-Device).',
  notificationTitle: 'Delete Warning',
  resultInRemoval: 'Existing enrolled devices won\'t be affected.',
  title: 'Delete',
  toProceed: 'To proceed, enter the number of enrollment tokens that will be deleted.',
};

export default {
  addAndroidAppText,
  androidEnterprisePreEnrollmentText,
  androidEmmAdminConfigText,
  androidEmmDeleteAppsText,
  androidEmmDeleteRegistrationText,
  androidEmmDeleteTokensText,
  androidEmmDeviceDetailsText,
  androidEmmEnrollmentText,
  androidEmmRegistrationText,
  androidEmmSoftwareApps,
  androidEmmUserConfigText,
  androidEmmQrEnrollmentText,
  androidEmmZeroTouchAdminConfigText,
  creatAndroidDeviceText,
  enrollmentTokenText,
  googleAppsDetailsText,
  enrollmentTypesText,
};
