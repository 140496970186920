const SystemSearchConsts = {
  displayName: 'System name',
  hostname: 'Host name',
  version: 'OS version',
  arch: 'Architecture',
  amazonInstanceID: 'Amazon instance ID',
  systemID: 'JumpCloud system ID',
  mdmDeviceID: 'MDM device ID',
  agentVersion: 'JC agent version',
  serialNumber: 'Serial number',
  remoteIP: 'Remote IP',
  osFamily: 'OS Family',
  supportedDevices: 'Supported Devices',
  locationName: 'Location Name',
};

const SystemSearchValueMap = {
  [SystemSearchConsts.displayName]: 'displayName',
  [SystemSearchConsts.hostname]: 'hostname',
  [SystemSearchConsts.version]: 'version',
  [SystemSearchConsts.arch]: 'arch',
  [SystemSearchConsts.amazonInstanceID]: 'amazonInstanceID',
  [SystemSearchConsts.systemID]: '_id',
  [SystemSearchConsts.mdmDeviceID]: 'mdm.internal.deviceId',
  [SystemSearchConsts.agentVersion]: 'agentVersion',
  [SystemSearchConsts.serialNumber]: 'serialNumber',
  [SystemSearchConsts.remoteIP]: 'remoteIP',
  [SystemSearchConsts.osFamily]: 'osFamily',
};

export default {
  SystemSearchConsts,
  SystemSearchValueMap,
};
