export default {
  runAsUsers: {
    /**
     * For Mac/Linux users, this is the identifier for the `root` runAs user.
     */
    root: '000000000000000000000000',
  },
};

export const CommandsConsts = {
  // eslint-disable-next-line
  linuxListUsersCommand: `awk -F'[/:]' '{if ($3 >= 1000 && $3 != 65534) print $1}' /etc/passwd`,
  // eslint-disable-next-line
  macChangeHostnameCommand: `#!/bin/bash
  conf="$(cat /opt/jc/jcagent.conf)"
  regex='\\"systemKey\\":\\"[a-zA-Z0-9]{24}\\"'

  if [[ $conf =~ $regex ]]; then
      systemKey="\${BASH_REMATCH[@]}"
  fi

  echo "$(date "+%Y-%m-%dT%H:%M:%S"): systemKey = $systemKey "

  regex='[a-zA-Z0-9]{24}'
  if [[ $systemKey =~ $regex ]]; then
      systemID="\${BASH_REMATCH[@]}"
  fi

  echo "$(date "+%Y-%m-%dT%H:%M:%S"): systemID = $systemID "

  now=$(date -u "+%a, %d %h %Y %H:%M:%S GMT")

  # create the string to sign from the request-line and the date
  signstr="GET /api/systems/\${systemID} HTTP/1.1\\ndate: \${now}"

  # create the signature
  signature=$(printf "$signstr" | openssl dgst -sha256 -sign /opt/jc/client.key | openssl enc -e -a | tr -d '\\n')

  search=$(
      curl \\
          -X 'GET' "${window.location.origin}/api/systems/\${systemID}" \\
          -H 'Content-Type: application/json' \\
          -H 'Accept: application/json' \\
          -H "Date: \${now}" \\
          -H "Authorization: Signature keyId=\\"system/\${systemID}\\",headers=\\"request-line date\\",algorithm=\\"rsa-sha256\\",signature=\\"\${signature}\\"" \\
  )

  # regex pattern to search displayNames. This pattern selects the text after "displayName":" and before ",
  regex='("displayName": ?")([^"]*)(",)'
  if [[ $search =~ $regex ]]; then
      match="\${BASH_REMATCH[2]}"
  fi

  echo $match

  # set the hostname, localhostname and computer name
  scutil --set HostName "$match"
  scutil --set LocalHostName "$match"
  scutil --set ComputerName "$match"

  # print out the names
  echo "HostName:" && scutil --get HostName
  echo "LocalHostName:" && scutil --get LocalHostName
  echo "ComputerName:" && scutil --get ComputerName`,
  // eslint-disable-next-line
  macCheckUserForSecureToken: `# Enter the username of the admin you wish to check for a secure token
  # Enter this within the "" of SECURETOKEN_ADMIN_USERNAME=""

  SECURETOKEN_ADMIN_USERNAME=""

  # Enter a username of an admin user to authorize the sysadminctl command with
  # Enter this within the "" of ADMIN_USERNAME=""

  ADMIN_USERNAME=""

  # Enter the password of this admin user
  # Enter this within the "" of ADMIN_PASSWORD=""

  ADMIN_PASSWORD=""

  # -------- Do not modify below this line --------

  sysadminctl -adminUser $ADMIN_USERNAME -adminPassword $ADMIN_PASSWORD -secureTokenStatus $SECURETOKEN_ADMIN_USERNAME`,
  // eslint-disable-next-line
  macListUsersCommand: `dscl . list /Users | grep -v '^_' | grep -v 'daemon' | grep -v 'nobody' | grep -v 'root'`,
  // eslint-disable-next-line
  macSetBackgroundCommand: `# *** Customize ***

  # 1. Update the 'backgroundURL' to the URL of your desired desktop image. A JumpCloud image is used by default.
  backgroundURL="https://raw.githubusercontent.com/TheJumpCloud/support/master/PowerShell/JumpCloud%20Commands%20Gallery/Files/JumpCloud_Background.png"


  # 2. Ensure the 'fileType' matches the file type of the desktop image (change to jpg if using a jpg). 'png' is set by default.
  fileType="png"


  # ------------Do not modify below this line-------------

  date_val=$(date "+%Y-%m-%d-%H%M")
  backgroundFile=/Users/Shared/desktopimage_$date_val.$fileType
  curl -s -o "$backgroundFile" "$backgroundURL"

read -r -d '' PSCRIPT<<EOF
from AppKit import NSWorkspace, NSScreen
from Foundation import NSURL

# generate a fileURL for the desktop picture
file_url = NSURL.fileURLWithPath_('$backgroundFile')

# make image options dictionary
# we just make an empty one because the defaults are fine
options = {}

# get shared workspace
ws = NSWorkspace.sharedWorkspace()

# iterate over all screens
for screen in NSScreen.screens():
    # tell the workspace to set the desktop picture
    (result, error) = ws.setDesktopImageURL_forScreen_options_error_(
                file_url, screen, options, None)

EOF

  env python -c "$PSCRIPT"`,
  // eslint-disable-next-line
  windowsChangeHostnameCommand: `wmic computersystem where caption='desktopname' rename newnamehere`,
  // eslint-disable-next-line
  windowsListUsersCommand: `$UserAccounts = Get-WmiObject -Class Win32_UserAccount -Filter {LocalAccount = "True"} | select Name, Disabled\nWrite-Output $UserAccounts`,
  // eslint-disable-next-line
  windowsRunAsUserCommand: `# If PSModule RunAsUser is not installed, install it
  if ( -not (get-installedModule "RunAsUser" -ErrorAction SilentlyContinue)) {
      install-module RunAsUser -force
  }
  else{
      $Command = {

      #Powershell Command Goes Here.

      }
      invoke-ascurrentuser -scriptblock $Command
  }`,
  macInstallCrowdStrikeFalcon: `#!/bin/bash
DownloadUrl="https://path/to/url.pkg"

############### Do Not Edit Below This Line ###############

fileName="FalconSensor.pkg"
# Create Temp Folder
DATE=$(date '+%Y-%m-%d-%H-%M-%S')

TempFolder="Download-$DATE"

mkdir /tmp/$TempFolder

# Navigate to Temp Folder
cd /tmp/$TempFolder

# Download File into Temp Folder
curl -o "$fileName" "$DownloadUrl"

installer -verboseR -package "/tmp/$TempFolder/$fileName" -target /

# Remove Temp Folder and download
rm -r /tmp/$TempFolder
echo "Deleted /tmp/$TempFolder"`,

  windowsInstallCrowdStrikeFalcon: `$CID="ENTER CID WITH CHECKSUM VALUE HERE"
$installerURL="ENTER URL TO FALCON AGENT INSTALLER HERE"

############### Do Not Edit Below This Line ###############

$installerTempLocation="C:\\Windows\\Temp\\CSFalconAgentInstaller.exe"

if (Get-Service "CSFalconService" -ErrorAction SilentlyContinue) {
    Write-Host "Falcon Agent already installed, nothing to do."
    exit 0
}
Write-Host "Falcon Agent not installed."

Write-Host "Downloading Falcon Agent installer now."
try {
    Invoke-WebRequest -Uri $installerURL -OutFile $installerTempLocation
}
catch {
    Write-Error "Unable to download Falcon Agent installer."
    exit 1
}
Write-Host "Finished downloading Falcon Agent installer."

Write-Host "Installing Falcon Agent now, this may take a few minutes."
try {
    $args = @("/install","/quiet","/norestart","CID=$CID")
    $installerProcess = Start-Process -FilePath $installerTempLocation -Wait -PassThru -ArgumentList $args
}
catch {
    Write-Error "Failed to run Falcon Agent installer."
    exit 1
}
Write-Host "Falcon Agent installer returned $($installerProcess.ExitCode)."

exit $installerProcess.ExitCode`,
};
