export default {
  beforeunload: 'beforeunload',

  /**
   * The adminEvent is a key set in local storage that all open tabs for the MTP
   * dashboard can listen for. This allows the dashboard to reactively update
   * if the settings for the logged in admin are updated.
   */
  adminEvent: 'adminEvent',

  /**
   * The logoutEvent is a key set in local storage that all open tabs for the MTP
   * dashboard will listen for. When this field is added to local storage all open tabs
   * will redirect to #/logout.
   */
  logoutEvent: 'logoutEvent',

  /**
   * The orgEvent is a key set in local storage that all open tabs for the MTP dashboard
   * can listen for. When this field is added to local storage, all open tabs that are
   * launched for a specific organization in the MTP will fetch a fresh copy of the organizations
   * list so that changes made in one tab can be reflected in all open tabs.
   */
  orgEvent: 'orgEvent',

  /**
   * Listeners for changes to the above additions to local storage will register
   * event listeners for the storage event and inspect the key to check which event
   * is triggered.
   */
  storage: 'storage',
};
