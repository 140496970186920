import ActiveDirectorySyncToADLogoSvg from 'img/activeDirectory/sync-direction-ad-bw.svg?url';
import ActiveDirectorySyncToBiDirectionLogoSvg from 'img/activeDirectory/sync-direction-bidirectional-bw.svg?url';
import ActiveDirectorySyncToJCLogoSvg from 'img/activeDirectory/sync-direction-jc-bw.svg?url';

import ActiveDirectoryADLogo from 'img/activeDirectory/ad-logo-bw.svg?url';
import ActiveDirectoryBiDirectionLogo from 'img/activeDirectory/bidirection-logo-bw.svg?url';
import ActiveDirectoryJCLogo from 'img/activeDirectory/jc-logo-bw.svg?url';

/**
 * These groups correspond to the top level grouping of directories in the new navigation.
 */
export const DirectoryGroupConsts = {
  activeDirectory: 'active-directory',
  cloudDirectories: 'cloud-directories',
  hrDirectories: 'hr-directories',
};

export const DirectoryScopes = {
  user: 'https://www.googleapis.com/auth/admin.directory.user',
  group: 'https://www.googleapis.com/auth/admin.directory.group',
  domain: 'https://www.googleapis.com/auth/admin.directory.domain.readonly',
};

export const DirectoryTypeKeys = {
  activeDirectory: 'activeDirectory',
  bambooHR: 'bambooHR',
  bob: 'bob',
  gSuite: 'gSuite',
  ldapServer: 'ldapServer',
  namely: 'namely',
  office365: 'office365',
  paylocity: 'paylocity',
  personio: 'personio',
  okta: 'okta',
  workday: 'workday',
  deel: 'deel',
  factorial: 'factorial',
  dayforce: 'ceridiandayforcehcm',
};

export const DirectoryAppsTemplatesNames = [
  DirectoryTypeKeys.namely,
  DirectoryTypeKeys.paylocity,
  DirectoryTypeKeys.personio,
  DirectoryTypeKeys.dayforce,
];

export const DirectoryTypeConsts = {
  [DirectoryTypeKeys.activeDirectory]: 'active_directory',
  [DirectoryTypeKeys.bambooHR]: 'bambooHR',
  [DirectoryTypeKeys.gSuite]: 'g_suite',
  [DirectoryTypeKeys.ldapServer]: 'ldap_server',
  [DirectoryTypeKeys.office365]: 'office_365',
  [DirectoryTypeKeys.okta]: 'okta',
  [DirectoryTypeKeys.workday]: 'workday',
  [DirectoryTypeKeys.deel]: 'deel',
  [DirectoryTypeKeys.factorial]: 'factorial',
};

// NOTE: This is referenced by the underscore delimited directory type value
// seen in DirectoryTypeConsts because this field is returned in each
// directory record returned from the server. It is used to ensure we display
// the proper name for the directory in the UI, e.g. Google Workspace instead of Google
// Apps.
export const DirectoryNameConsts = {
  [DirectoryTypeConsts.activeDirectory]: 'Active Directory Domain',
  [DirectoryTypeKeys.bambooHR]: 'BambooHR',
  [DirectoryTypeConsts.bob]: 'bob',
  [DirectoryTypeConsts.deel]: 'Deel',
  [DirectoryTypeConsts.factorial]: 'Factorial',
  [DirectoryTypeConsts.gSuite]: 'Google Workspace',
  [DirectoryTypeKeys.namely]: 'Namely',
  [DirectoryTypeConsts.ldapServer]: 'JumpCloud LDAP',
  [DirectoryTypeConsts.office365]: 'M365/Azure AD',
  [DirectoryTypeConsts.okta]: 'Okta',
  [DirectoryTypeKeys.paylocity]: 'Paylocity',
  [DirectoryTypeKeys.personio]: 'Personio',
  [DirectoryTypeConsts.workday]: 'Workday Import',
  [DirectoryTypeKeys.dayforce]: 'Ceridian Dayforce HCM',
};

export const AdBridgeAgentStates = {
  active: 'active',
  inactive: 'inactive',
  unsealed: 'unsealed',
};

export const AdBridgeAgentTypes = {
  importAgent: 'importAgent',
  syncAgent: 'syncAgent',
};

export const DirectoryActionConsts = {
  activated: 'activated',
  cloudDirectoryReactivated: 'cloudDirectoryReactivated',
  create: 'create',
  currentState: 'currentState',
  details: 'details',
  groupsEnabled: 'groupsEnabled',
  error: 'error',
  import_users: 'import-users',
  reactivated: 'reactivated',
  scopes: 'scopes',
  tabIndex: 'tabIndex',
};

export const gSuiteConstants = {
  errorMap: {
    INVALID_ARGUMENT: {
      message: 'Unable to retrieve domains.',
      saveMessage: 'Invalid domain selected. Select a valid domain and try again.',
      default: 'There was a problem with the domains update.  Try again.',
    },
    SOURCEDOMAIN_ERROR: {
      message: 'Unable to load domains from google workspace',
    },
    PERMISSION_DENIED: 'Permission Denied',
  },
};

export const o365ApiConstants = {
  consistencyLevel: 'ConsistencyLevel',
  eventual: 'eventual',
  errorMap: {
    INVALID_ARGUMENT: {
      message: 'Unable to retrieve domains.',
      saveMessage: 'Invalid domain selected. Select a valid domain and try again.',
      default: 'There was a problem with the domains update.  Try again.',
    },
    SOURCEDOMAIN_ERROR: {
      message: 'Unable to load domains from M365/AAD',
    },
  },
};

export const o365Fields = {
  givenName: 'givenName',
  surname: 'surname',
  userPrincipalName: 'userPrincipalName',
};

export const DirectoryAttributeDirectionConsts = {
  export: {
    value: 'export',
    name: 'Export',
  },
  import: {
    value: 'import',
    name: 'Import',
  },
  exclude: {
    value: 'exclude',
    name: 'Exclude',
  },
};

export const DirectoryUserPrincipalNameFromAlternateEmail = {
  companyEmail: {
    value: 'companyEmail',
    name: 'Company Email',
  },
  alternateEmail: {
    value: 'alternateEmail',
    name: 'Alternate Email',
  },
};

export const GSuiteAttributesConsts = [
  {
    direction: 'export',
    ownedByJC: true,
    id: 'firstname',
    jc: 'firstname',
    directory: 'name.givenName',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: true,
    id: 'lastname',
    jc: 'lastname',
    directory: 'name.familyName',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: true,
    id: 'password',
    jc: 'password',
    directory: 'password',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: true,
    id: 'email',
    jc: 'email',
    directory: 'primaryEmail',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_home_addresses',
    jc: 'addresses[type=home]',
    directory: 'addresses[type=home]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_home_phone_numbers',
    jc: 'phoneNumbers[type=home]',
    directory: 'phones[type=home]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_mobile_phone_numbers',
    jc: 'phoneNumbers[type=mobile]',
    directory: 'phones[type=mobile]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_work_addresses',
    jc: 'addresses[type=work]',
    directory: 'addresses[type=work]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_work_phone_numbers',
    jc: 'phoneNumbers[type=work]',
    directory: 'phones[type=work]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_work_fax_phone_numbers',
    jc: 'phoneNumbers[type=work_fax]',
    directory: 'phones[type=work_fax]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_work_mobile_phone_numbers',
    jc: 'phoneNumbers[type=work_mobile]',
    directory: 'phones[type=work_mobile]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_primary_organization_description',
    jc: 'employeeType',
    directory: 'primaryOrganization.description',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_primary_organization_department',
    jc: 'department',
    directory: 'primaryOrganization.department',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_primary_organization_employee_id',
    jc: 'employeeIdentifier',
    directory: 'externalIds[]',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_primary_organization_cost_center',
    jc: 'costCenter',
    directory: 'primaryOrganization.costCenter',
    status: true,
  },
  {
    direction: 'export',
    ownedByJC: false,
    id: 'user_primary_organization_title',
    jc: 'jobTitle',
    directory: 'primaryOrganization.title',
    status: true,
  },
];

export const UseCaseConstants = {
  JCASAUTHORITY: 'JCASAUTHORITY',
  ADASAUTHORITY: 'ADASAUTHORITY',
  TWOWAYSYNC: 'TWOWAYSYNC',
  UNSET: 'UNSET',
};

// the keys at the upper level represent the previous selection,
// while the keys nested within each upper-level key represent the current selection
export const updateAgentUseCaseMessageConsts = {
  [UseCaseConstants.JCASAUTHORITY]: {
    [UseCaseConstants.ADASAUTHORITY]: 'For this update to be complete, the import agent needs to be downloaded and installed and all sync agents must be uninstalled.',
    [UseCaseConstants.TWOWAYSYNC]: 'For this update to be complete, the import agent needs to be downloaded and installed.',
  },
  [UseCaseConstants.ADASAUTHORITY]: {
    [UseCaseConstants.JCASAUTHORITY]: 'For this update to be complete, the sync agent needs to be downloaded and installed and all import agents must be uninstalled.',
    [UseCaseConstants.TWOWAYSYNC]: 'For this update to be complete, the sync agent needs to be downloaded and installed.',
  },
  [UseCaseConstants.TWOWAYSYNC]: {
    [UseCaseConstants.ADASAUTHORITY]: 'For this update to be complete, all sync agents must be uninstalled.',
    [UseCaseConstants.JCASAUTHORITY]: 'For this update to be complete, all import agents must be uninstalled.',
  },
};

export const updatePasswordActiveAgentsMessageConsts = {
  [UseCaseConstants.JCASAUTHORITY]: {
    [UseCaseConstants.ADASAUTHORITY]: 'For this update, install the import agent and uninstall all sync agents.',
    [UseCaseConstants.TWOWAYSYNC]: 'For this update, install the import agent.',
  },
  [UseCaseConstants.ADASAUTHORITY]: {
    [UseCaseConstants.JCASAUTHORITY]: 'For this update, install the sync agent and uninstall all import agents.',
    [UseCaseConstants.TWOWAYSYNC]: 'For this update, install the sync agent.',
  },
  [UseCaseConstants.TWOWAYSYNC]: {
    [UseCaseConstants.ADASAUTHORITY]: 'For this update, uninstall all sync agents.',
    [UseCaseConstants.JCASAUTHORITY]: 'For this update, uninstall all import agents.',
  },
};

export const updatePasswordUseCaseMessageConsts = {
  [UseCaseConstants.JCASAUTHORITY]: ['Users must log in with their JumpCloud password.', 'All password changes will be made in JumpCloud.'],
  [UseCaseConstants.ADASAUTHORITY]: ['Users must log in with their AD password.', 'All password changes will be made in AD.'],
  [UseCaseConstants.TWOWAYSYNC]: ['Passwords changes can be made in both JumpCloud and AD.'],
};

export const updateDelegatePasswordValidation = {
  enabled: ['Password status for associated users will be set to delegated.', 'Password validation will be delegated to AD.', 'Passwords will only sync to JumpCloud if the import agent is installed on all DCs.'],
  disabled: [
    'Delegated password status for associated users will be removed', 'Passwords will be validated by JumpCloud', 'End users must set or change their passwords in JumpCloud If import agents are installed on member servers and passwords are being set or changed  in AD.'],
};

export const usesCasesConfig = {
  [UseCaseConstants.JCASAUTHORITY]: {
    configurationTitle: 'Manage users and passwords in JumpCloud',
    helperText: 'Users, groups, and passwords are created, updated, and managed in JumpCloud and then synced to AD.',
    helpUrl: 'https://jumpcloud.com/support/configure-adi-sync-from-jumpcloud-to-ad',
    installAgentsSubText: '',
    installAgentsSubTextUpdateCase: 'To complete the change, you\'ll need to install the sync agent.',
    installAgentsText: 'For your integration to be complete, you will need to install the sync agent.',
    label: 'Manage users and passwords in JumpCloud',
    passwordAuthorityLogo: ActiveDirectoryJCLogo,
    passwordAuthorityText: 'JumpCloud',
    passwordAuthorityTextNew: 'JumpCloud',
    syncDirectionLogo: ActiveDirectorySyncToJCLogoSvg,
    syncDirectionText: 'JumpCloud to AD',
    useCase: UseCaseConstants.JCASAUTHORITY,
  },
  [UseCaseConstants.ADASAUTHORITY]: {
    configurationTitle: 'Manage users and passwords in Active Directory',
    helperText: 'Users, groups, and passwords are created, updated, and managed in AD and then synced to JumpCloud.',
    helpUrl: 'https://jumpcloud.com/support/configure-adi-sync-from-ad-to-jumpcloud',
    installAgentsSubText: 'Copy and paste the connect key in the installer screens.',
    installAgentsSubTextUpdateCase: 'To complete the change, you\'ll need to install the import agent.',
    installAgentsText: 'For your integration to be complete, you will need to install the sync agent.',
    label: 'Manage users and passwords in Active Directory',
    passwordAuthorityLogo: ActiveDirectoryADLogo,
    passwordAuthorityText: 'Active Directory',
    passwordAuthorityTextNew: 'Active Directory',
    syncDirectionLogo: ActiveDirectorySyncToADLogoSvg,
    syncDirectionText: 'AD to JumpCloud',
    useCase: UseCaseConstants.ADASAUTHORITY,
  },
  [UseCaseConstants.TWOWAYSYNC]: {
    configurationTitle: 'Manage users and passwords in either system or both',
    helperText: 'Configure a 2-way sync to create and update users, groups, and passwords in either system, or both.',
    helpUrl: 'https://jumpcloud.com/support/configure-adi-two-way-sync',
    installAgentsSubText: '',
    installAgentsSubTextUpdateCase: 'To complete the change, you’ll need to install both agents.',
    installAgentsText: 'For your integration to be complete, you will need to install both agents.',
    label: 'Manage users and passwords in JumpCloud, AD or both',
    passwordAuthorityLogo: ActiveDirectoryBiDirectionLogo,
    passwordAuthorityText: 'JumpCloud and Active Directory',
    passwordAuthorityTextNew: 'JumpCloud and AD',
    syncDirectionLogo: ActiveDirectorySyncToBiDirectionLogoSvg,
    syncDirectionText: 'Bi-Directional',
    useCase: UseCaseConstants.TWOWAYSYNC,
  },
};

export const AdBridgeStatusesConsts = {
  disabled: 'DISABLED',
  enabled: 'ENABLED',
  pending: 'PENDING',
};

export const GSuiteImportOptionsConfig = {
  MANUALUSERIMPORT: {
    label: 'Select specific new users to import',
    helperText: 'View and select specific new users to import from Google into JumpCloud. A suspended user in Google will import as suspended in JumpCloud.',
    passwordAuthorityLogo: 'img/activeDirectory/ad-jumpcloud-logos.svg',
  },
  ADDORUPDATEUSERS: {
    label: 'All new users and user updates',
    helperText: 'Import all new users and user updates from Google into JumpCloud.',
    passwordAuthorityLogo: 'img/activeDirectory/jc-logo.svg',
  },
  ADDNEWUSERS: {
    label: 'All new users',
    helperText: 'Import all new users from Google into JumpCloud.',
    passwordAuthorityLogo: 'img/activeDirectory/active-directory-logo.svg',
  },
  UPDATEEXISTINGUSERS: {
    label: 'All user updates',
    helperText: 'Import all user updates from Google into JumpCloud.',
    passwordAuthorityLogo: 'img/activeDirectory/ad-jumpcloud-logos.svg',
  },
};

export const AttributeMappingImpacts = {
  EXCLUDEPASSWORD: {
    headerText: 'Attribute Mapping Impacts',
    confirmationMessage: 'Are you sure you want to exclude passwords from syncing?',
    cardInfo: 'Excluding the password from syncing to Google will impact the Password Configuration setting.',
    cardDescription: 'If JumpCloud is not managing the password in Google, Password Expiration and User Lockout will be set to **Maintain Access**, which means users bound to Google will maintain access to Google regardless of password status in JumpCloud.',
    link: 'https://jumpcloud.com/support/sync-user-attributes-with-google-workspace#to-select-attributes-to-export-or-import',
    userPasswordExpirationAction: 'maintain',
    userLockOutAction: 'maintain',
  },
  IMPORTOREXCLUDEUSERSTATE: {
    headerText: 'Attribute Mapping Impacts',
    confirmationMessage: 'Are you sure you want to Import or Exclude User State?',
    cardInfo: 'Selecting Import or Exclude for the User State will impact the Password Configuration setting.',
    cardDescription: 'If the User State is not managed by JumpCloud, **Suspend Users** will change to **Remove Access** in Password Expiration, and to **Maintain** in Account Lockout.',
    link: 'https://jumpcloud.com/support/sync-user-attributes-with-google-workspace#to-select-attributes-to-export-or-import',
    userPasswordExpirationAction: 'remove_access',
    userLockOutAction: 'maintain',
  },
  EXPORTPASSWORD: {
    headerText: 'Attribute Mapping Effects',
    confirmationMessage: 'Are you sure you want to sync the password to Google?',
    cardInfo: 'Selecting Export for the Password will impact the Password Configuration setting.',
    cardDescription: 'Because JumpCloud will be managing the password for users bound to Google, Password Expiration will be set to **Remove Access**. Users with expired JumpCloud passwords will continue to receive email in Google Workspace but won\'t be able to log in until they change their JumpCloud Password.',
    link: 'https://jumpcloud.com/support/sync-user-attributes-with-google-workspace#to-select-attributes-to-export-or-import',
    userPasswordExpirationAction: 'remove_access',
    userLockOutAction: 'maintain',
  },
};

export const Operations = {
  ADDORUPDATEUSERS: ['users.create', 'users.update'],
  ADDNEWUSERS: ['users.create'],
  UPDATEEXISTINGUSERS: ['users.update'],
};

export const DelegationActionMessage = {
  disabled: [
    {
      title: 'Bound User(s):',
      messages:
      [
        'AD will automatically be removed as the delegated password authority.',
        'User(s) won’t be able to log in to JumpCloud managed resources until they have a JumpCloud password.',
      ],
    },
  ],
  enabled: [
    {
      title: 'Bound User(s):',
      messages:
      [
        'AD will automatically be set as the delegated password authority.',
        'User(s) must log in to any JumpCloud managed resources that support delegation with their AD password.',
        'If a user is bound to multiple delegation-enabled AD domains they will not be able to log into JumpCloud managed resources.',
      ],
    },
  ],
  bulk: [
    {
      title: 'Unbound User(s):',
      messages:
      [
        'If you **choose to update** unbound users and this is the last association the user has to the AD integration, AD will automatically be removed as the delegated password authority and the user log in will fail when accessing JumpCloud managed resources until they have a JumpCloud password.',
        'If you **choose to NOT update** unbound users and this is the last association the user has to the AD integration, user login will fail.',
      ],
    },
    {
      title: 'Bound User(s):',
      messages:
      [
        'If you **choose to update** bound users, AD will automatically be set as the delegated password authority, meaning user(s) must log in with their AD password to any JumpCloud managed resources that support delegation. **Note:** If a user is bound to multiple delegation-enabled AD domains they will not be able to log into JumpCloud managed resources.',
        'If you **choose to NOT update** bound users there will be no change.  Users will continue to log in with their JumpCloud password to all JumpCloud managed resources. ',
      ],
    },
  ],
};

export const AgentWithDelegation = 'v3.0.0';

export const ImportType = {
  csv: 'csv',
  gsuite: 'gsuite',
  office365: 'office365',
  scim: 'scim',
  workday: 'workday',
};

export const AdDelegatedAuthSupportArticle = 'https://jumpcloud.com/support/adi-use-ad-delegated-authentication';
