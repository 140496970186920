import * as DirectoryConstants from 'client/util/Constants/DirectoryConstants';
import * as PaymentConstants from 'client/Payments/constants/PaymentConstants';
import * as PoliciesConstants from 'client/util/Constants/PoliciesConstants';
import { CommandsConsts } from 'client/util/Constants/CommandConstants';
import { planModuleName } from '@jumpcloud-ap/plans';
import AndroidEmmConstants from 'client/util/Constants/AndroidEmmConstants';
import ApiLevelToAndroidVersionMappingConstants from 'client/util/Constants/ApiLevelToAndroidVersionMappingConstants';
import BodyComponentConstants from 'client/util/Constants/BodyComponentConstants';
import ConfigurationCategoriesConstants from 'client/util/Constants/ConfigurationCategoriesConstants';
import CustomEmailConsts from 'client/util/Constants/CustomEmailConstants';
import DeviceConstants from 'client/util/Constants/DeviceConstants';
import DirectoryGroupingConstants from 'client/util/Constants/DirectoryGroupingConstants';
import EventConstants from 'client/util/Constants/EventConstants';
import ImportUsersConstants from 'client/util/Constants/ImportUsersConstants';
import ItemTypeConstants from 'client/util/Constants/ItemTypeConstants';
import KnowledgeBaseConstants from 'client/util/Constants/KnowledgeBaseConstants';
import LocalStorageConstants from 'client/util/Constants/LocalStorageConstants';
import LoginConstants from 'client/util/Constants/LoginConstants';
import LogoUndefinedPlaceholderPng from 'img/logoUndefinedPlaceholder.png?url';
import LogoUndefinedPng from 'img/logoUndefined.png?url';
import MdmFormsConstants from 'client/util/Constants/MdmFormsConstants';
import SettingsConstants from 'client/util/Constants/SettingsConstants';
import SignupConstants from 'client/util/Constants/SignupConstants';
import SystemCommandsConstants from 'client/util/Constants/SystemCommandsConstants';
import SystemInsightsConstants from 'client/util/Constants/SystemInsightsConstants';
import SystemSearchConstants from 'client/util/Constants/SystemSearchConstants';
import UserApiErrorsConstants from 'client/util/Constants/userAPIErrorsConstants';
import UserScopeConstants from 'client/util/Constants/UserScopeConstants';
import UsersConstants from './Constants/UsersConstants';

const assetsUrl = 'https://s3.amazonaws.com/static.jumpcloud.com';

/**
 * Create Constants object that can be referenced globally
 * instead of magic numbers or strings
 */
export default {
  android: 'Android',
  apple: 'Apple',
  assetsUrl,
  csrf: 'csrf',
  dataNotAvailable: '—', // Note this is actually the &emdash; character!
  dateFormat: 'MM-DD-YYYY',
  dateTimeFormat: 'MMM DD, YYYY [@] hh:mma',
  dateMonthFormat: 'MMMM DD, YYYY',
  dateTimeFullMonthFormat: 'MMMM DD, YYYY, hh:mm A',
  dateTimeLongFormat: 'MM-DD-YYYY [at] hh:mma',
  dateTimestampFormat: 'YYYYMMDDHHmmss',
  // dateTimeSecondsFormat is used within the Activity Log. The format has been changed
  // because MM-DD-YYYY isn't supported for the vue.js datepicker. The vue.js
  // datepicker uses Date.parse('MM-DD-YYYY') which returns NaN on firefox and safari.
  dateTimeSecondsFormat: 'MMM DD, YYYY [@] HH:mm:ss.SS',
  darwin: 'Mac OS X',
  environments: {
    local: 'local',
    wok: 'wok',
    staging: 'staging',
    production: 'production',
  },
  error: 'error',
  info: 'info',
  iOS: 'iOS',
  iPadOS: 'iPadOS',
  warn: 'warn',
  jcUndefinedLogo: LogoUndefinedPng,
  jcUndefinedLogoPlaceholder: LogoUndefinedPlaceholderPng,
  linux: 'Linux',
  mac: 'Mac OS X',
  macOS: 'Mac OS',
  macOS2: 'macOS',
  macOSX: 'Mac OS X',
  modalFrameDelay: 60,
  neverExpiresText: 'Never expires',
  noResultsIllustration: `${assetsUrl}/illustrations/no-results-tumbleweed.svg`,
  orgNameNotSet: '[ org name not set ]',
  orgNameNotSetLongVersion: 'organization name not set',
  filterNames: {
    os: 'os',
    osMetaFamily: 'template.os_meta_family',
    mdmVendor: 'mdm.vendor',
    version: 'version',
  },
  redirectTo: LoginConstants.redirectTo,
  success: 'success',
  sudoerTypes: {
    noElevatedPermissions: 'no-elevated-permissions',
    standardUser: 'standard-user',
    sudo: 'sudo',
    sudoWithoutPassword: 'sudo-without-password',
  },
  systemGroup: 'systemGroup',
  timeFormat: 'HH:mm:ss',
  timezoneOffsetMinutes: 'timezone_offset_minutes',
  tvOS: 'tvOS',
  unknown: 'Unknown',
  userGroup: 'userGroup',
  windows: 'Windows',
  xsrf: 'xsrf',
};

export const ApplePackageManagers = {
  appleCustom: 'APPLE_CUSTOM',
  appleVpp: 'APPLE_VPP',
};

export const GooglePackageManagers = {
  android: 'GOOGLE_ANDROID',
};

export const WindowsPackageManagers = {
  chocolatey: 'CHOCOLATEY',
  windowsMDM: 'WINDOWS_MDM',
  microsoftStore: 'MICROSOFT_STORE',
  appCatalog: 'WINGET',
};

export const AuthIdpConsts = {
  idpProviderValues: {
    Azure: 'AZURE AD',
    Jumpcloud: 'JUMPCLOUD',
  },
  Azure: 'AZURE',
};

export const AndroidEmmConsts = AndroidEmmConstants;

export const { ApiLevelToAndroidVersionMappingConsts } = ApiLevelToAndroidVersionMappingConstants;

export const BodyComponentConsts = BodyComponentConstants;

export const DeviceConsts = DeviceConstants;

export const Commands = CommandsConsts;

export const CommandTemplatesConsts = {
  sources: {
    custom: 'Custom',
    jumpcloud: 'JumpCloud',
  },
  types: {
    linux: 'linux',
    mac: 'mac',
    windows: 'windows',
  },
};

export const CheckboxStateConsts = {
  checked: 'checked',
  indeterminate: 'indeterminate',
  unchecked: 'unchecked',
};

export const SystemCommandsConsts = SystemCommandsConstants;

export const UsersConsts = UsersConstants;

export const {
  AuthnPolicyConsts,
  DarwinPoliciesNamesConsts,
  iOSPoliciesNamesConsts,
  GooglePolicyConsts,
  WindowsPoliciesNamesConsts,
} = PoliciesConstants;

export const ResetTOTPConsts = {
  headerText: 'Reset TOTP MFA on Admins',
  bodyText: 'Resetting TOTP Multi-factor Authentication clears the existing TOTP configuration on this admin. This admin will receive an email to reset their TOTP and is required to reset it before logging into the Admin Portal.',
};

export const ConfigurationCategoriesConsts = ConfigurationCategoriesConstants;

export const DashboardConsts = {
  dashboardMenuItem: 'Multi-Tenant Portal Dashboard',
  locationLabel: 'Dashboard',
  menuTitle: 'Multi-Tenant Portal',
  route: 'dashboard',
};

export const DirectoryGroupingConsts = DirectoryGroupingConstants;

export const {
  AdBridgeStatusesConsts,
  AdBridgeAgentStates,
  AdBridgeAgentTypes,
  DirectoryActionConsts,
  DirectoryAttributeDirectionConsts,
  DirectoryGroupConsts,
  DirectoryNameConsts,
  DirectoryTypeConsts,
  DirectoryTypeKeys,
  DirectoryScopes,
  DirectoryUserPrincipalNameFromAlternateEmail,
  UseCaseConstants,
} = DirectoryConstants;

export const DropDownSectionConsts = {
  multiSelect: 'multi-select',
  selectOne: 'select one',
};

export const EventConsts = EventConstants;

export const ExportFileTypes = {
  csv: 'csv',
  json: 'json',
};

export const FileSizes = {
  megaByte: 1024,
};

export const FilterComponentConsts = {
  AppleSoftwareManagementDropDownFilter: 'AppleSoftwareManagementDropDownFilter',
  ApplicationsDropDownFilter: 'ApplicationsDropDownFilter',
  ApplicationsSearchFilter: 'ApplicationsSearchFilter',
  BrowserPolicyDropDownFilter: 'BrowserPolicyDropDownFilter',
  CommandsDropDownFilter: 'CommandsDropDownFilter',
  CommandTemplatesDropDownFilter: 'CommandTemplatesDropDownFilter',
  DeviceGroupDropDownFilter: 'DeviceGroupDropDownFilter',
  GroupAsideSystemDropDownFilter: 'GroupAsideSystemDropDownFilter',
  GSuiteImportSelectionDropDownFilter: 'GSuiteImportSelectionDropDownFilter',
  O365ImportSelectionDropDownFilter: 'O365ImportSelectionDropDownFilter',
  OsPolicyDropDownFilter: 'OsPolicyDropDownFilter',
  PolicyDropDownFilter: 'PolicyDropDownFilter',
  PolicyCategoryDropDownFilter: 'PolicyCategoryDropDownFilter',
  PolicySystemDropDownFilter: 'PolicySystemDropDownFilter',
  PolicySystemStatusSearchFilter: 'PolicySystemStatusSearchFilter',
  PolicySystemStatusDropDownFilter:
    'PolicySystemStatusDropDownFilter',
  ScimUsersImportSelectionDropDownFilter: 'ScimUsersImportSelectionDropDownFilter',
  SystemDropDownFilter: 'SystemDropDownFilter',
  SystemSearchFilter: 'SystemSearchFilter',
  SystemUserDropDownFilter: 'SystemUserDropDownFilter',
  SystemUserSearchFilter: 'SystemUserSearchFilter',
  UserGroupDropDownFilter: 'UserGroupDropDownFilter',
  UserGroupEnrollDropDownFilter: 'UserGroupEnrollDropDownFilter',
  WindowsSoftwareManagementDropDownFilter: 'WindowsSoftwareManagementDropDownFilter',
};

export const IdentityManagement = {
  test: 'test',
  activate: 'activate',
  update: 'update',
};

export const ImportUsersConsts = ImportUsersConstants;

export const ItemActionConsts = {
  create: 'create',
  update: 'update',
};

export const ItemTableConsts = {
  keepFreshInterval: 30 * 1000,

  // Column names that are used in more than one ItemTable parent component are
  // added here
  columnTypes: {
    adminMfaStatusColumn: 'adminMfaStatusColumn',
    codeColumn: 'codeColumn',
    dateColumn: 'dateColumn',
    editableFieldColumn: 'editableFieldColumn',
    emailColumn: 'emailColumn',
    iconColumn: 'iconColumn',
    mfaStatusColumn: 'mfaStatusColumn',
    nameColumn: 'nameColumn',
    osColumn: 'osColumn',
    passwordStatusColumn: 'passwordStatusColumn',
    radiusMfaStatusColumn: 'radiusMfaStatusColumn',
    selectColumn: 'selectColumn',
    stateColumn: 'stateColumn',
    statusColumn: 'statusColumn',
    sudoStatusColumn: 'sudoStatusColumn',
    viewReplyAttributesColumn: 'viewReplyAttributesColumn',
  },

  detailsButtonType: {
    default: 'default',
    caret: 'caret',
    rowExpand: 'rowExpand',
  },
  deviceFamilies: {
    ipad: 'IPAD',
    iphone: 'IPHONE',
    ipod: 'IPOD',
    mac: 'MAC',
  },
  panelType: {
    adDomainAsidePanel: 'adDomainAsidePanel',
    adminModal: 'adminModal',
    applicationAsidePanel: 'applicationAsidePanel',
    commandAsidePanel: 'commandAsidePanel',
    directoryAsidePanel: 'directoryAsidePanel',
    groupAsidePanel: 'groupAsidePanel',
    mainPanel: 'mainPanel',
    modal: 'modal',
    mtp: 'mtp',
    policiesAsidePanel: 'policiesAsidePanel',
    softwareAppsAsidePanel: 'softwareAppsAsidePanel',
    systemAsidePanel: 'systemAsidePanel',
    userAsidePanel: 'userAsidePanel',
  },

  parentOSType: {
    android: 'android',
    darwin: 'darwin',
    ios: 'ios',
    linux: 'linux',
    universal: 'universal',
    windows: 'windows',
  },

  commandOSType: {
    darwin: 'mac',
    linux: 'linux',
    windows: 'windows',
  },
};

export const osFamilyTypes = ItemTableConsts.parentOSType;
export const commandOsFamilyTypes = ItemTableConsts.commandOSType;

export const SignInWithJCOSType = {
  administrator: 'ADMIN',
  administratorLabel: 'Administrator',
  macOS: 'MACOS',
  standard: 'STANDARD',
  standardLabel: 'Standard',
  windows: 'WINDOWS',
};

export const SignInWithJCAPI = {
  signin: 'signinwithjumpcloud',
};

export const {
  ItemTypeAPIs,
  ItemTypeConsts,
  ItemTypeDisplayNames,
  ItemTypeLabels,
  ItemTypeRoutes,
  getDetailsRoute,
  getStatusRoute,
} = ItemTypeConstants;

export const KnowledgeBaseConsts = KnowledgeBaseConstants;

export const CustomEmailConstants = CustomEmailConsts;
export const LocalStorageConsts = LocalStorageConstants;
export const LoginConsts = LoginConstants;

export const MdmFormsConsts = MdmFormsConstants.MdmFormsConstants;
export const MdmFormsTypes = MdmFormsConstants.FormType;

export const MdmEventsStatus = {
  Acknowledged: 'Success',
  Error: 'Error',
  Idle: 'Idle',
  NotNow: 'Device Offline',
};

export const MdmTabsIndex = {
  mdm: 0,
  vpp: 1,
};

export const DeviceTabs = {
  android: 'android',
  ios: 'ios',
  linux: 'linux',
  macos: 'macos',
  windows: 'windows',
};

export const DeviceSupportedOSLabels = {
  linux: 'Linux',
  macos: 'macOS',
  windows: 'Windows',
  iod: 'iOS',
  android: 'Android',
};

export const DefaultDeviceGroupErrorMessages = {
  defaultMacOSADEGroupFailureMsg: 'system group is a default macOS ADE device group for Apple MDM',
  defaultIosADEGroupFailureMsg: 'system group is a default iOS ADE device group for Apple MDM',
  defaultIosUserEnrollmentGroupFailureMsg: 'system group is a default iOS user device group for Apple MDM',
};

export const MdmVendorStates = {
  internal: 'internal',
  external: 'external',
  none: 'none',
  unknown: 'unknown',
};

export const ModuleConsts = {
  systemUser: {
    errors: {
      failedActivationEmailResend:
        'Problem resending activation email to selected user(s).',
      selectedItemsEmpty: 'No users selected.',
      unableToFetchItem: 'Unable to fetch user.',
      usersCouldNotBeDeleted: 'The user(s) could not be deleted.',
      usersCouldNotBeSuspended: 'The user(s) could not be suspended.',
      usersCouldNotBeActivated: 'The user(s) could not be activated.',
    },

    accountStates: {
      activated: 'ACTIVATED',
      staged: 'STAGED',
      suspended: 'SUSPENDED',
    },

    passwordStates: {
      active: 'active',
      expired: 'expired',
      inactive: 'inactive',
      locked: 'locked',
      pending: 'pending',
    },

    mfaStates: {
      active: 'active',
      enrollment: 'enrollment',
      enrollmentExpired: 'enrollment expired',
      inactive: 'inactive',
      pending: 'pending',
    },
  },

  user: {
    errors: UserApiErrorsConstants,

    accountStates: {
      active: 'active',
      staged: 'staged',
      suspended: 'suspended',
    },

    passwordStates: {
      pending: 'pending',
    },

    mfaStates: {
      active: 'active',
      inactive: 'inactive',
    },
  },

  system: {
    errors: {
      selectedItemsEmpty: 'No systems selected.',
      systemsCouldNotBeDeleted: 'The system(s) could not be deleted.',
      unableToFetchItem: 'Unable to fetch system.',
    },
  },
};

export const ChatConsts = {
  delayTime: 2 * 60 * 1000, // Delay opening by 2 minutes
  popoverBodyText: 'Accelerate your learning and implementation with live chat support from our JumpCloud engineers for your first 10 days.',
  popoverHeaderText: '24x7 Live Chat Support',
  trialLength: 10, // Length of chat trial: 10 days
};

export const GSuiteConsts = {
  wistiaIframeGSuiteVideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/td1tyo67xn',
  wistiaGSuiteVideoUrl: 'https://jumpcloud-1.wistia.com/medias/td1tyo67xn',
};

const wistiaDirectoryInsightsVideoId = '1t4v7uqdjx';
const wistiaJumpCloudDemoVideoId = 'zvlqfs2pgc';
const wistiaMdmGettingSetupVideoId = 'kx7a6y116f';
const newWistiaMdmGettingSetupVideoId = '8ikf24ja50';

export const GettingStartedConsts = {
  newWistiaMdmGettingSetupIframeUrl: `https://jumpcloud-1.wistia.com/embed/iframe/${newWistiaMdmGettingSetupVideoId}`,
  newWistiaMdmGettingSetupVideoUrl: `https://jumpcloud-1.wistia.com/medias/${newWistiaMdmGettingSetupVideoId}`,
  popoverBodyText: 'Our easy-to-follow onboarding guide makes getting started a breeze.',
  popoverHeaderText: 'Get Started with JumpCloud',
  wistiaDirectoryInsightsGettingSetupVideoUrl: `https://fast.wistia.net/embed/iframe/${wistiaDirectoryInsightsVideoId}`,
  wistiaJumpCloudDemoVideoId,
  wistiaMdmGettingSetupVideoId,
  wistiaJumpCloudDemoIframeVideoUrl: `https://jumpcloud-1.wistia.com/embed/iframe/${wistiaJumpCloudDemoVideoId}`,
  wistiaJumpCloudDemoVideoUrl: `https://jumpcloud-1.wistia.com/medias/${wistiaJumpCloudDemoVideoId}`,
  wistiaMdmGettingSetupVideoUrl: `https://fast.wistia.net/embed/iframe/${wistiaMdmGettingSetupVideoId}`,
};

export const GroupsConsts = {
  userGroupsWistiaIframeVideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/vlvyk0fu8h',
  userGroupsWistiaVideoUrl: 'https://jumpcloud-1.wistia.com/medias/vlvyk0fu8h',
  systemGroupsWistiaIframeVideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/i933051caz',
  systemGroupsWistiaVideoUrl: 'https://jumpcloud-1.wistia.com/medias/i933051caz',
  inheritedGroupPermissions: 'Inherited Group Permissions',
};

export const LogicalOperators = {
  and: 'and',
  or: 'or',
};

export const LoginModalConsts = {
  excludeModalTestingCookie: 'jc_excludeModalTesting',
};

export const NotificationConsts = {
  groupedTypes: {
    groupConflicts: 'GROUP_CONFLICTS',
    securityAlerts: 'SECURITY_ALERTS',
    userConflicts: 'USER_CONFLICTS',
    userFailures: 'USER_FAILURES',
  },
  notificationTypes: {
    gidConflictType: 'GID-CONFLICT',
    groupGIDConflictType: 'GroupGID-CONFLICT',
    md5SecAlertType: 'MD5SUM-CHANGE',
    uidConflictType: 'UID-CONFLICT',
  },
};

export const O365Consts = {
  wistiaIframeO365VideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/91n9cqcgi1',
  wistiaO365VideoUrl: 'https://jumpcloud-1.wistia.com/medias/91n9cqcgi1',
};

export const OnboardingChecklistConsts = {
  newWistiaLinuxSystemSetupIframe: 'https://jumpcloud-1.wistia.com/embed/iframe/69md0h5rq4',
  newWistiaMacSystemSetupIframe: 'https://jumpcloud-1.wistia.com/embed/iframe/7pfmqcstk4',
  newWistiaWindowsSystemSetupIframe: 'https://jumpcloud-1.wistia.com/embed/iframe/9grjqbkgsb',
  newWistiaAndroidSetupIframe: 'https://jumpcloud-1.wistia.com/embed/iframe/71jxbhpu7u',
  newWistiaLinuxSystemSetupVideo: 'https://jumpcloud-1.wistia.com/medias/69md0h5rq4',
  newWistiaMacSystemSetupVideo: 'https://jumpcloud-1.wistia.com/medias/7pfmqcstk4',
  newWistiaWindowsSystemSetupVideo: 'https://jumpcloud-1.wistia.com/medias/9grjqbkgsb',
  newWistiaAndroidSetupVideo: 'https://jumpcloud-1.wistia.com/medias/71jxbhpu7u',
  wistiaLinuxSystemSetupVideo: 'https://fast.wistia.net/embed/iframe/65eskvdepy',
  wistiaMacSystemSetupVideo: 'https://fast.wistia.net/embed/iframe/2vqd58y0fm',
  wistiaWindowsSystemSetupVideo: 'https://fast.wistia.net/embed/iframe/2t35gnwh9q',
  wistiaAndroidSetupVideo: 'https://fast.wistia.net/embed/iframe/71jxbhpu7u',
};

export const PasswordConsts = {
  passwordCompliance: {
    custom: 'custom',
    pci3: 'pci3',
    windows: 'windows',
  },
};

export const PaymentConsts = { ...PaymentConstants };

export const PolicyConsts = {
  FILE_VAULT_TEMPLATE_NAME: 'filevault-2_darwin',
  BITLOCKER_TEMPLATE_NAME: 'bitlocker_fde_windows',
  fileVaultIframeTutorialVideoUrl: 'https://jumpcloud-1.wistia.com/embed/iframe/0gy281wb8y',
  fileVaultTutorialVideoUrl: 'https://jumpcloud-1.wistia.com/medias/0gy281wb8y',

  policyTemplateTypes: {
    android: 'android',
    ios: 'ios',
    linux: 'linux',
    mac: 'darwin',
    universal: 'universal',
    windows: 'windows',
  },

  policyTemplateDisplayValues: {
    android: 'android',
    darwin: 'mac',
    ios: 'ios',
    linux: 'linux',
    universal: 'universal',
    windows: 'windows',
  },
};

export const PolicyStatusConsts = {
  duplicate: 'duplicate',
  failed: 'failed',
  pending: 'pending',
  success: 'success',
  uninstallFailed: 'uninstall_failed',
  uninstallPending: 'uninstall_pending',
  unsupportedOs: 'unsupported_os',
};

export const PolicyStatusSearchConsts = {
  systemID: 'JumpCloud system ID',
  exitStatus: 'Exit status',
};

export const PolicyStatusSearchValueMap = {
  [PolicyStatusSearchConsts.systemID]: 'system_id',
  [PolicyStatusSearchConsts.exitStatus]: 'exit_status',
};

export const PricingLink = {
  pricing: 'https://jumpcloud.com/pricing',
};

export const SettingsConsts = SettingsConstants;

export const SignupConsts = SignupConstants;

export const Sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const SoftwareAppDesiredStatusConsts = {
  INSTALL: {
    value: 'INSTALL',
    notificationLabel: 'Installing to devices.',
    bindingAction: 'INSTALL',
  },
  UNINSTALL: {
    value: 'UNINSTALL',
    notificationLabel: 'Uninstalling from devices.',
    bindingAction: 'UNINSTALL',
  },
  autoUpdate: {
    value: 'autoUpdate',
    notificationLabel: 'Installing to devices and keeping app up to date.',
    bindingAction: 'INSTALL',
  },
};

export const SoftwareAppBindingModalConsts = {
  INSTALL: {
    bindingPreposition: 'to',
    bindingActionLabel: 'be installed to',
    bindingInfoText: 'After installation the software will be managed by JumpCloud.\n\n*Pro Tip: We recommend that you test installing managed software prior to binding to your entire fleet.*',
    unbindingPreposition: 'on',
    unbindingActionLabel: 'no longer be managed on',
  },
  UNINSTALL: {
    bindingPreposition: 'from',
    bindingActionLabel: 'be uninstalled from',
    bindingInfoText: 'Software that is uninstalled is still managed by JumpCloud.',
    unbindingPreposition: 'on',
    unbindingActionLabel: 'no longer be managed on',
  },
};

export const SoftwareAppStatusConsts = {
  FAILED: {
    iconColorClass: 'critical',
  },
  PENDING: {
    iconColorClass: 'pending',
  },
  SUCCESS: {
    iconColorClass: 'active',
  },
  UNINSTALL_FAILED: {
    iconColorClass: 'critical', message: 'FAILURE', value: 'UNINSTALL_FAILED', status: 'Uninstall Failed',
  },
  UNINSTALL_PENDING: {
    iconColorClass: 'pending', message: 'PENDING', value: 'UNINSTALL_PENDING', status: 'Uninstall Pending',
  },
  UNINSTALL_SUCCESS: {
    iconColorClass: 'active', message: 'SUCCESS', value: 'UNINSTALL_SUCCESS', status: 'Uninstall Success',
  },
};

export const VppSoftwareAppStatusConsts = {
  INSTALL_FAILED: {
    iconColorClass: 'critical', message: 'FAILURE', value: 'COMMAND FAILED', status: 'Install Failed',
  },
  INSTALL_PENDING: {
    iconColorClass: 'pending', message: 'PENDING', value: 'INSTALL PENDING', status: 'Install Pending',
  },
  INSTALL_SUCCESS: {
    iconColorClass: 'active', message: 'SUCCESS', value: 'COMMAND SENT', status: 'Install Success',
  },
  LICENSE_FAILED: {
    iconColorClass: 'critical', message: 'FAILURE', value: 'LICENSE FAILED', status: 'License Failed',
  },
  MDM_UNENROLLED: {
    iconColorClass: 'informational', value: 'MDM_UNENROLLED', status: 'MDM Unenrolled',
  },
  PENDING: {
    iconColorClass: 'pending',
  },
  UNINSTALL_FAILED: {
    iconColorClass: 'critical', message: 'FAILURE', value: 'UNINSTALL FAILED', status: 'Uninstall Failed',
  },
  UNINSTALL_PENDING: {
    iconColorClass: 'pending', message: 'PENDING', value: 'UNINSTALL PENDING', status: 'Uninstall Pending',
  },
  UNINSTALL_SUCCESS: {
    iconColorClass: 'active', message: 'SUCCESS', value: 'UNINSTALL SUCCESS', status: 'Uninstall Success',
  },
  UPDATE_FAILED: {
    iconColorClass: 'critical', message: 'FAILURE', value: 'UPDATE FAILED', status: 'Update Failed',
  },
  UPDATE_PENDING: {
    iconColorClass: 'pending', message: 'PENDING', value: 'UPDATE PENDING', status: 'Update Pending',
  },
  UPDATE_SUCCESS: {
    iconColorClass: 'active', message: 'SUCCESS', value: 'UPDATE SUCCESS', status: 'Update Success',
  },
  INSTALL_SUCCESS_STATUS: 'INSTALL_SUCCESS',
};

export const VppSupportedDeviceConst = {
  iPad: 'IPAD',
  iPhone: 'IPHONE',
  mac: 'MAC',
};

export const AppAuthorizationRecommendedApps = {
  list: [
    'Keeper Password Manager',
    'Zscaler',
    'OneDrive',
    'Microsoft Teams',
    'Microsoft Excel',
    'Microsoft Word',
    'Microsoft PowerPoint',
    'Microsoft Outlook',
    'Microsoft OneNote',
  ],
  columnLabel: 'JumpCloud Trusted',
};

export const StatusColorNames = {
  active: 'active',
  critical: 'critical',
  info: 'info',
  inactive: 'inactive',
  warning: 'warning',
};

export const SudoConsts = {
  elevatePermission: 'Elevate Permissions',
  globalSudo: 'Global Administrator/Sudo',
  globalPasswordless: 'Global Passwordless Sudo',
  groupPermissions: 'Group Permissions',
  individualPermissions: 'Individual Permissions',
  noPermissions: 'No Elevated Permissions',
  passwordless: 'Passwordless Sudo',
  sudo: 'Administrator/Sudo',

};

export const StoreModelMap = {
  [ItemTypeConsts.activeDirectory]: 'ActiveDirectoryModel',
  [ItemTypeConsts.appAuthorization]: 'AppAuthorizationModel',
  [ItemTypeConsts.application]: 'ApplicationModel',
  [ItemTypeConsts.alerts]: 'AlertModel',
  [ItemTypeConsts.authNPolicies]: 'AuthNPolicyModel',
  [ItemTypeConsts.bookmark]: 'ApplicationModel',
  [ItemTypeConsts.command]: 'CommandModel',
  [ItemTypeConsts.commandTemplate]: 'CommandTemplateModel',
  [ItemTypeConsts.configuration]: 'ConfigurationModel',
  [ItemTypeConsts.configurationGroup]: 'ConfigurationGroupModel',
  [ItemTypeConsts.customPolicyGroupTemplates]: 'CustomPolicyGroupTemplateModel',
  [ItemTypeConsts.device]: 'SystemModel',
  [ItemTypeConsts.deviceGroup]: 'SystemGroupModel',
  [DirectoryTypeConsts.geolocation]: 'GeolocationModel',
  [DirectoryTypeConsts.gSuite]: 'GSuiteDirectoryModel',
  [ItemTypeConsts.ipList]: 'IpListModel',
  [ItemTypeConsts.identityProviders]: 'IdentityProvidersModel',
  [ItemTypeConsts.ldap]: 'LdapModel',
  [ItemTypeConsts.mdmSettings]: 'MdmSettingsModel',
  [DirectoryTypeConsts.office365]: 'O365DirectoryModel',
  [ItemTypeConsts.organizations]: 'OrganizationModel',
  [ItemTypeConsts.payment]: 'PaymentModel',
  [ItemTypeConsts.radiusServer]: 'RadiusServerModel',
  [ItemTypeConsts.rule]: 'RuleModel',
  [ItemTypeConsts.ruleTemplates]: 'RuleTemplateModel',
  [ItemTypeConsts.samba]: 'SambaModel',
  [ItemTypeConsts.settings]: 'SettingsModel',
  [ItemTypeConsts.softwareApps]: 'SoftwareAppsModel',
  [ItemTypeConsts.sso]: 'ApplicationModel',
  [ItemTypeConsts.system]: 'SystemModel',
  [ItemTypeConsts.systemGroup]: 'SystemGroupModel',
  [ItemTypeConsts.systemUser]: 'SystemUserModel',
  [ItemTypeConsts.systemUserWebAuthnKeys]: 'SystemUserWebAuthnKeysModel',
  [ItemTypeConsts.userGroup]: 'UserGroupModel',
  [ItemTypeConsts.workday]: 'WorkdayModel',
};

export const StoreModuleMap = {
  [ItemTypeConsts.adBridgeAgent]: 'AdBridgeAgentCollection',
  [ItemTypeConsts.alerts]: 'AlertsCollection',
  [ItemTypeConsts.appAuthorization]: 'AppAuthorizationCollection',
  [ItemTypeConsts.application]: 'ApplicationCollection',
  [ItemTypeConsts.applicationTemplate]: 'ApplicationTemplateCollection',
  [ItemTypeConsts.asidePanelEvents]: 'AsidePanelEventsModule',
  [ItemTypeConsts.authNPolicies]: 'AuthNPoliciesCollection',
  [ItemTypeConsts.browserPatchManagement]: 'BpmPolicyCollection',
  [ItemTypeConsts.command]: 'CommandsCollection',
  [ItemTypeConsts.commandTemplate]: 'CommandTemplateCollection',
  [ItemTypeConsts.commandQueue]: 'CommandQueueCollection',
  [ItemTypeConsts.commandQueueWorkflow]: 'CommandQueueWorkflowCollection',
  [ItemTypeConsts.commandCustomTemplate]: 'CommandCustomTemplateCollection',
  [ItemTypeConsts.commandTemplateWindows]: 'CommandTemplateWindowsCollection',
  [ItemTypeConsts.commandTemplateMacOs]: 'CommandTemplateMacOsCollection',
  [ItemTypeConsts.commandTemplateLinux]: 'CommandTemplateLinuxCollection',
  [ItemTypeConsts.commandResult]: 'CommandResultsCollection',
  [ItemTypeConsts.commandRunner]: 'CommandRunnerCollection',
  [ItemTypeConsts.configuration]: 'PolicyCollection',
  [ItemTypeConsts.configurationGroup]: 'ConfigurationGroupCollection',
  [ItemTypeConsts.customPolicyGroupTemplates]: 'CustomPolicyGroupTemplateCollection',
  [ItemTypeConsts.defaultPolicyGroupTemplates]: 'DefaultPolicyGroupTemplateCollection',
  [ItemTypeConsts.device]: 'SystemCollection',
  [ItemTypeConsts.deviceAppStatus]: 'DeviceAppStatusCollection',
  [ItemTypeConsts.deviceGroup]: 'SystemGroupCollection',
  [ItemTypeConsts.dgSystemsPreview]: 'DGSystemsPreviewCollection',
  [ItemTypeConsts.dgSystemUsersPreview]: 'DGSystemUsersPreviewCollection',
  [ItemTypeConsts.dgSystemsReview]: 'DGSystemsReviewCollection',
  [ItemTypeConsts.dgUsersReview]: 'DGUsersReviewCollection',
  [ItemTypeConsts.directory]: 'DirectoryCollection',
  [ItemTypeConsts.googleEmmEnrollmentTokens]: 'GoogleEmmEnrollmentTokenCollection',
  [ItemTypeConsts.insightsEvent]: 'InsightsEventModule',
  [ItemTypeConsts.identityProviders]: 'IdentityProvidersCollection',
  [ItemTypeConsts.ipList]: 'IpListsCollection',
  [ItemTypeConsts.mdmEvents]: 'MdmEventsModule',
  [ItemTypeConsts.microsoftStoreSearch]: 'MicrosoftStoreSearchCollection',
  [ItemTypeConsts.featureTrial]: 'FeatureTrialModule',
  [ItemTypeConsts.geolocation]: 'GeolocationCollection',
  [ItemTypeConsts.group]: 'GroupCollection',
  [ItemTypeConsts.gSuite]: 'GSuiteImportCollection',
  [ItemTypeConsts.gSuiteDetails]: 'GSuiteDetailsCollection',
  [ItemTypeConsts.growth]: 'GrowthModule',
  [ItemTypeConsts.idsource]: 'AdBridgeAgentCollection',
  [ItemTypeConsts.importUsers]: 'ImportUsersCollection',
  [ItemTypeConsts.knowledge]: 'WhatsNewModule',
  [ItemTypeConsts.mdmGoogle]: 'GoogleModule',
  [ItemTypeConsts.office365]: 'O365ImportCollection',
  [ItemTypeConsts.office365Details]: 'O365DetailsCollection',
  [ItemTypeConsts.organizations]: 'OrganizationsCollection',
  [ItemTypeConsts.osPatchManagement]: 'OspPolicyCollection',
  [ItemTypeConsts.partners]: 'PartnersModule',
  [ItemTypeConsts.plan]: planModuleName,
  [ItemTypeConsts.policyStatus]: 'PolicySystemStatusCollection',
  [ItemTypeConsts.policy]: 'PolicyCollection',
  [ItemTypeConsts.policyGroupTemplate]: 'PolicyGroupTemplateCollection',
  [ItemTypeConsts.policyTemplate]: 'PolicyTemplateCollection',
  [ItemTypeConsts.policyTemplateAndroid]: 'PolicyTemplateAndroidCollection',
  [ItemTypeConsts.policyTemplateIos]: 'PolicyTemplateIosCollection',
  [ItemTypeConsts.policyTemplateLinux]: 'PolicyTemplateLinuxCollection',
  [ItemTypeConsts.policyTemplateMac]: 'PolicyTemplateMacCollection',
  [ItemTypeConsts.policyTemplateWindows]: 'PolicyTemplateWindowsCollection',
  [ItemTypeConsts.ruleTemplates]: 'RuleTemplateCollection',
  [ItemTypeConsts.pushMfaUserEndpoints]: 'PushMfaUserEndpointsModule',
  [ItemTypeConsts.providers]: 'ProvidersCollection',
  [ItemTypeConsts.radiusServer]: 'RadiusServerCollection',
  [ItemTypeConsts.reports]: 'ReportsCollection',
  [ItemTypeConsts.rule]: 'RuleCollection',
  [ItemTypeConsts.scimUserImport]: 'ScimUsersImportCollection',
  [ItemTypeConsts.settings]: 'SettingsModule',
  [ItemTypeConsts.sharedFolder]: 'SharedFolderCollection',
  [ItemTypeConsts.softwareApps]: 'SoftwareAppsCollection',
  [ItemTypeConsts.softwareAppsStatus]: 'SoftwareAppsStatusCollection',
  [ItemTypeConsts.sso]: 'ApplicationCollection',
  [ItemTypeConsts.ssoTemplate]: 'ApplicationTemplateCollection',
  [ItemTypeConsts.ssoV2]: 'ApplicationCollectionV2',
  [ItemTypeConsts.ssoTemplateV2]: 'ApplicationTemplateCollectionV2',
  [ItemTypeConsts.suggestedSystemUser]: 'SuggestedSystemUsersCollection',
  [ItemTypeConsts.system]: 'SystemCollection',
  [ItemTypeConsts.systemGroup]: 'SystemGroupCollection',
  [ItemTypeConsts.systemInsights]: 'SystemInsightsModule',
  [ItemTypeConsts.systemPolicyStatus]: 'SystemPolicyStatusCollection',
  [ItemTypeConsts.systemNeedsAttention]: 'SystemNeedsAttentionCollection',
  [ItemTypeConsts.systemUnacknowledged]: 'SystemUnacknowledgedCollection',
  [ItemTypeConsts.systemUser]: 'SystemUserCollection',
  [ItemTypeConsts.user]: 'UserCollection',
  [ItemTypeConsts.userDisplayPreferences]: 'UserDisplayPreferencesModule',
  [ItemTypeConsts.userGroup]: 'UserGroupCollection',
  [ItemTypeConsts.mdmVpp]: 'VppModule',
  [ItemTypeConsts.workdayImportedUser]: 'WorkdayImportedUserCollection',
  [ItemTypeConsts.workdayWorker]: 'WorkdayWorkerCollection',
  [ItemTypeConsts.featureFlags]: 'FeatureFlagsModule',
};

export const SystemCapabilitiesConsts = {
  commandDeployment: 'Command Deployment',
  deviceTrust: 'Device Trust',
  macAuthentication: 'Mac Authentication and Identity Management',
  osPatchMgmt: 'OS Patch Management',
  patchManagement: 'Patch Management',
  policyMgmt: 'Policy Management',
  remote: 'Remote Security Commands (Lock, Restart, Shutdown & Erase)',
  softwareMgmt: 'Software Management',
  zeroTouch: 'Zero-Touch Enrollment',
};

export const SystemCompatibilityConsts = {
  linux: [
    // All supported architectures are to be noted by the distribution name,
    // unless the architectures are not homogenous across the distribution's
    // versions. i.e. If the JumpCloud agent is built for both amd64 and arm64
    // architectures, it is noted by the distribution name. If only some of the
    // versions have the JumpCloud agent built for each architecture, they are
    // specified by the version number.
    // RPM-based
    '**Amazon Linux (amd64, arm64):** 2, 2023',
    '**Fedora:** 38 (amd64), 39 (amd64, arm64), 40 (amd64, arm64)',
    '**RHEL:** 7 (amd64), 8 (amd64), 9 (amd64, arm64)',
    '**Rocky Linux (amd64, arm64):** 8, 9',
    // Deb-based
    '**Debian:** 10 (amd64), 11 (amd64, arm64), 12 (amd64, arm64)',
    '**Linux Mint (Cinnamon) (amd64):** 20, 21',
    '**Ubuntu (amd64, arm64):** 18.04, 20.04, 22.04, 24.04',
    '**Pop!_OS (amd64):** 22.04',
  ],
  mac: ['12.x, 13.x., 14.x, 15.x'],
  windows: ['10 (64 bit), 11', '**Server:** 2016 (64 bit), 2019, 2022', '**Note:** International versions of Windows are supported, however, certain international character sets may cause cosmetic issues in the Systems detail views and/or adverse results in Command execution in the Admin Console.'],
};

export const SystemInsightsConsts = SystemInsightsConstants;
export const { SystemSearchConsts } = SystemSearchConstants;
export const { SystemSearchValueMap } = SystemSearchConstants;

export const TableWithPaginationConsts = {
  rowsPerPageOptions: [10, 20, 50],
};

export const UniversityConsts = {
  url: 'http://www.jumpcloud.com/university',
};

export const AddNewDeviceConsts = {
  windows: {
    msiDownloadEnrollment: 'msiDownloadEnrollment',
    powershellInstall: 'powershellInstall',
    createPackage: 'createPackage',
  },
};

export const exposeIdmBaseUrl = {
  spaceIQ: {
    name: 'spaceiq',
    baseUrl: 'https://api.spaceiq.com/scim',
  },
  profit: {
    name: 'profit',
    baseUrl: 'https://[region].profit.co/app/rest/platform/auth/scim/v2',
  },
};

export const UnsavedChangesModalConsts = {
  headerText: 'Unsaved Changes',
  bodyText: 'The changes you\'ve made will be lost if you leave this page. Would you like to continue?',
};

export const UserRoleConsts = {
  administrator: 'Administrator',
  administratorWithBilling: 'Administrator With Billing',
  billingOnly: 'Billing Only',
  commandRunner: 'Command Runner',
  commandRunnerWithBilling: 'Command Runner With Billing',
  helpDesk: 'Help Desk',
  manager: 'Manager',
  privilegedAccessAdmin: 'Privileged Access Administrator',
  readOnly: 'Read Only',
};

export const UserScopeConsts = UserScopeConstants;

export const UserSearchConsts = {
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  userID: 'User ID',
  username: 'Username',
  company: 'Company',
  costCenter: 'Cost Center',
  displayName: 'Display name',
  department: 'Department',
  employeeID: 'Employee ID',
  employeeType: 'Employee type',
  jobTitle: 'Job title',
  location: 'Location',
  status: 'Status',
  systemUsername: 'Local User Account',
};

export const jumpcloudUserAttributes = ['firstname', 'lastname', 'password', 'email', 'user_principal_name_from_alternate_email'];

export const UserSearchValueMap = {
  [UserSearchConsts.company]: 'company',
  [UserSearchConsts.costCenter]: 'costCenter',
  [UserSearchConsts.displayName]: 'displayname',
  [UserSearchConsts.department]: 'department',
  [UserSearchConsts.email]: 'email',
  [UserSearchConsts.employeeID]: 'employeeIdentifier',
  [UserSearchConsts.employeeType]: 'employeeType',
  [UserSearchConsts.firstName]: 'firstname',
  [UserSearchConsts.jobTitle]: 'jobTitle',
  [UserSearchConsts.lastName]: 'lastname',
  [UserSearchConsts.location]: 'location',
  [UseCaseConstants.status]: 'status',
  [UserSearchConsts.systemUsername]: 'systemUsername',
  [UserSearchConsts.userID]: '_id',
  [UserSearchConsts.username]: 'username',
};

export const WorkdayConsts = {
  importResultStatus: {
    pending: 'pending',
    started: 'started',
    inProgress: 'in progress',
    finished: 'finished',
    errored: 'errored',
    canceled: 'canceled',
  },
};

/**
 * Default values for XMLHttpRequests
 */
export const XhrConsts = {
  CSRFInputName: '_csrf',
  XsrfInputName: '_xsrf',
  xCSRFToken: 'X-CSRF-Token',
  xOrgIdHeader: 'X-Org-Id',
  xOrgIdKey: 'jc-orgId',
  xTotalCount: 'X-Total-Count',
  xXsrfToken: 'X-Xsrftoken',
  xRedirectHeader: 'x-redirect-to',
};

export const GettingStartedRootLink = {
  url: `${assetsUrl}/adminportal/getting-started-panel`,
};

export const FallbackPolicyStatus = {
  allow: {
    indicator: 'success',
    label: 'Allow Authentication',
  },
  allowwithmfa: {
    indicator: 'success',
    label: 'Allow Authentication & Require MFA',
  },
  deny: {
    indicator: 'error',
    label: 'Deny Access',
  },
  legacy: {
    indicator: 'success',
    label: 'Require MFA based on user setting',
  },
  unknown: {
    indicator: 'inactive',
    label: 'Status Unavailable',
  },
  allowwithexternalidp: {
    indicator: 'success',
    label: 'Allow authentication with IDP',
  },
};

export const contactSupportLabels = {
  defaultErrorMessage: 'We were unable to submit your ticket. Please contact support directly with your issue at [support@jumpcloud.com.](mailto:support@jumpcloud.com)',
  paragraphBodyContext: 'Fill out the fields below with as much detail as possible, so we can help you better, and faster.',
  troubleshootPlaceholder: 'What are the primary steps we need to follow to reproduce this issue, or trigger the error? Also, include any troubleshooting steps you’ve already tried. It’s okay if you haven’t done anything, but knowing this helps us provide you with a solution you haven’t tried yet.',
  validCategories: [
    'Commands',
    'Devices (Agents, MDM, Remote Assist)',
    'Insights',
    'LDAP',
    'MFA (TOTP, Push, Protect)',
    'Password Manager',
    'Policies',
    'RADIUS',
    'Software Management',
  ],
};

export const IntegrationStatus = {
  active: 'Integration Active',
  inactive: 'Integration Inactive',
};
