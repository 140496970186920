import _ from 'lodash';

import $ from 'jquery';
import AlertWrapper from 'client/components/Alert/AlertWrapper';

import 'client/vendor/jquery-cookie.js';

// Don't require jQuery if already defined as any registered plugins will be lost!
if (!window.jQuery && !window.$) {
  window.$ = window.jQuery = $;
}

export function getCookieOptions() {
  return window.location.protocol === 'https:' ? { secure: true } : null;
}

export const queryString = function (queryString) {
  const query = (queryString || window.location.search).substring(1); // delete ?
  if (!query) {
    return false;
  }
  return _
    .chain(query.split('&'))
    .map((params) => {
      const p = params.split('=');
      return [p[0], decodeURIComponent(p[1])];
    })
    .object()
    .value();
};

/**
 * @description Get query string parameters with Javascript
 *
 * @see
 * https://www.npmjs.com/package/url-search-params
 * https://davidwalsh.name/query-string-javascript
 */
export const getUrlParameter = function getUrlParameter(name, hash = window.location.hash) {
  const key = name.replace(/\\/g, '\\\\').replace(/[[]/g, '\\[').replace(/[\]]/g, '\\]');
  const regex = new RegExp(`[\\?&]${key}=([^&#]*)`);
  const results = regex.exec(hash);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Displays a notification to the user. DEPRECATED. Do not use for new code
 *
 *
 * notify(msg)
 * notify(type, msg)
 * notify(type, msg, delay)
 * notify(type, msg, el, delay)
 *
 * @deprecated Use @jumpcloud-ap/notifications instead
 * @param {string} [type=info] - The alert type (info, error, warning, success)
 * @param {string} msg - The text to be displayed in the alert
 * @param {string} [el] - The container element of the alert
 * @param {number} [delay] - The number of milliseconds before the alert fades
 */
export const notify = (...args) => {
  let type = 'info';
  let msg = '';
  let el = '#messages';
  let delay = 7000; // default delay of 7 seconds

  if ($(el).length === 0) return;

  switch (args.length) {
    case 4:
      delay = args[3];
      el = args[2];
      msg = args[1];
      type = args[0];
      break;
    case 3:
      delay = args[2];
    case 2:
      msg = args[1];
      type = args[0];
      break;
    default:
      msg = args[0];
  }

  // attach empty element into DOM for the vue component to replace
  $('<div class="alert-container"></div>').prependTo(el);
  const alert = AlertWrapper('.alert-container', {
    type,
    text: msg,
  });

  if (delay) {
    window.setTimeout(() => {
      alert.$children[0].hide();
    }, delay);
  }

  return alert;
};

export function uuid() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
  });
  return uuid;
}

/**
 * Formats a javascript array as a human-readable series. Use a wrapper function to
 * get something a little more fancy.
 *
 * Basic Example:
 *   ['a', 'b', 'c'] => 'a, b, and c'
 *
 * Wrapper Example:
 *   ['a', 'b', 'c'], function (item) { return '<strong>' + item + '</strong>'; } =>
 *     '<strong>a</strong>, <strong>b</strong>, and <strong>c</strong>'.
 */
export const formatListAsSeries = function (list, wrapperFunc) {
  // Make sure we have a wrapper function if one isn't provided...
  if (!wrapperFunc || typeof wrapperFunc !== 'function') {
    wrapperFunc = function (item) { return item; };
  }

  // Make sure we have a proper list...
  if (!list || Object.prototype.toString.call(list) !== '[object Array]') {
    return wrapperFunc('');
  }

  // Scrub list for any empty / nonstring elements...
  const cleanList = [];
  for (const item in list) {
    if (typeof list[item] === 'string' && list[item].replace(/^\s+|\s+$/g, '').length > 0) {
      cleanList.push(list[item]);
    }
  }
  list = cleanList;

  // Sort the items alphabetically (ignoring case)...
  list.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  // Handle different list cases...
  if (list.length == 0) {
    return wrapperFunc('');
  }
  if (list.length == 1) {
    return wrapperFunc(list[0]);
  }
  if (list.length == 2) {
    return `${wrapperFunc(list[0])} and ${wrapperFunc(list[1])}`;
  }

  let series = '';
  for (let i = 0; i < list.length; i++) {
    if (i == list.length - 1) {
      series += `and ${wrapperFunc(list[i])}`;
    } else {
      series += `${wrapperFunc(list[i])}, `;
    }
  }
  return series;
};

export const ga = (...params) => {
  if (window.ga && typeof window.ga === 'function') {
    return window.ga(...params);
  }
};

export const getRandomNumberBetweenRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
