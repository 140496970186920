export const paymentMethods = {
  addNew: 'add new credit card',
  useCurrent: 'use current credit card',
};

export const nmiCss = {
  id: 'CollectJSIframe',
  bounceOut: 'CollectJSBounceOut',
  class: 'CollectJS',
  fade: 'CollectJSFade',
};

export const paymentFormSections = {
  addOnFeaturesSection: 'AddOnFeaturesSection',
  billingInformationSection: 'BillingInformationSection',
  subscriptionTypeSection: 'SubscriptionTypeSection',
  paymentMethodSection: 'PaymentMethodSection',
  finalReview: 'FinalReview',
};

/**
 * Messages that the UI displays after responses are received
 */
export const responseMessages = {
  setSubscriptionError: 'Unable to set subscription.',
};
