const sessionDurationDefault = {
  days: 0,
  hours: 0,
  minutes: 0,
};

export default {
  betaFeatures: {
    showMdmLink: 'showMdmLink',
  },
  isMsp: 'jc-isMsp',
  isMspAdminErrMsg: 'Unauthorized: no organization selected',
  isMspStatusCode: 401,
  // List of settings default Key/Value pairs
  keyValues: [
    { key: 'adBridgeSetupUrl', value: undefined },
    { key: 'adBridgeSyncAgentUrl', value: undefined },
    { key: 'betaFeatures', value: undefined },
    { key: 'clientSideRequestLimit', value: 5 },
    { key: 'connectKey', value: undefined },
    { key: 'contactEmail', value: '' },
    { key: 'contactName', value: '' },
    { key: 'csrfToken', value: undefined },
    { key: 'directoriesConnectKey', value: undefined },
    { key: 'directoryAgentVersion', value: undefined },
    { key: 'disableCommandRunner', value: false },
    { key: 'disableGoogleLogin', value: false },
    { key: 'disableUM', value: false },
    { key: 'emailDisclaimer', value: '' },
    { key: 'enableGoogleApps', value: false },
    { key: 'enableManagedUID', value: false },
    { key: 'enableO365', value: false },
    { key: 'enableUserPortalAgentInstall', value: false },
    { key: 'features', value: {} },
    { key: 'googleAnalyticsObject', value: 'ga' },
    { key: 'googleAppsUrl', value: undefined },
    { key: 'idleSessionDurationMinutes', value: 0 },
    { key: 'sessionDuration', value: sessionDurationDefault },
    { key: 'isCustomer', value: false },
    { key: 'jit', value: undefined },
    { key: 'kickstart', value: undefined },
    { key: 'latestWhatsNewPublishedDate', value: undefined },
    { key: 'logoUrl', value: undefined },
    { key: 'macAgentUrl', value: undefined },
    { key: 'maxSystemUsers', value: 10 },
    { key: 'name', value: '' },
    { key: 'newSystemUserStateDefaults', undefined },
    { key: 'orgId', value: undefined },
    { key: 'orgsCount', value: 1 },
    { key: 'passwordCompliance', value: undefined },
    { key: 'passwordPolicy', value: undefined },
    { key: 'requireAdminMFA', value: false },
    // TODO: It might be time to get rid of this in favor of role/roleName from /api/getSelf
    { key: 'role', value: undefined },
    { key: 'roles', value: [] },
    { key: 'syncAgentVersion', value: undefined },
    { key: 'systemUserPasswordExpirationInDays', value: 0 },
    { key: 'systemUsersCanEdit', value: true },
    { key: 'user', value: {} },
    { key: 'userNeedsPWReset', value: false },
    { key: 'userPassSet', value: false },
    { key: 'windowsAgentUrl', value: undefined },
  ],
  noProviderIdMsg: 'You do not have a Provider Id. Please <a href="https://go.jumpcloud.com/partner-app" target="_blank" rel="noopener noreferrer">apply to be an MSP Partner</a> to gain full-feature access.',
  providerId: 'jc-providerId',
  sessionDurationDefault,
  urlOrgLogo: 'orgLogo',
  urlOrgName: 'orgName',
  // These are from '/api/getSelf'
  userKeyValues: [
    { key: 'id', value: undefined },
    { key: 'apiKey', value: undefined },
    { key: 'connectKey', value: undefined },
    { key: 'created', value: undefined },
    { key: 'disableIntroduction', value: undefined },
    { key: 'email', value: undefined },
    { key: 'enableMultiFactor', value: undefined },
    { key: 'totpEnrolled', value: undefined },
    { key: 'firstname', value: undefined },
    { key: 'lastWhatsNewChecked', value: undefined },
    { key: 'lastname', value: undefined },
    { key: 'oauth', value: undefined },
    { key: 'provider', value: undefined },
    { key: 'role', value: undefined },
    { key: 'roleName', value: undefined },
    { key: 'sessionCount', value: undefined },
    { key: 'suspended', value: undefined },
    { key: 'usersTimeZone', value: undefined },
  ],
};
