export default {
  /**
   * Some actions you can take when toggling System Insights functionality.
   */
  action: {
    enable: 'enable',
    disable: 'disable',
  },

  /**
   * Field names returned on the settings system insights object.
   */
  autoEnableTypes: {
    enableNewDarwin: 'enableNewDarwin',
    enableNewWindows: 'enableNewWindows',
    enableNewLinux: 'enableNewLinux',
  },

  /**
   * States used by the API for whether System Insights is enabled or disabled.
   */
  systemState: {
    deferred: 'deferred',
    disabled: 'disabled',
    enabled: 'enabled',
  },

  /**
   * Names used in Insights dropdown for categorizing tables.
   */
  groups: {
    network: 'Network',
    operatingSystem: 'Operating System',
    software: 'Software',
    summary: 'Summary',
    systemAndHardware: 'System and Hardware',
    usersAndGroups: 'Local Users and Groups',
  },
};
