/**
 * Must run jquery as part of initialization or vite will not load it and adminportal will fail
 * when run locally. This is due to a bug in the version of bootstrap we have pinned, and can be
 * removed when we update it.
 *
 * This must be a separate file from admin-portal.js to ensure it gets loaded before any
 * boostrap assets
 *
 * @see https://github.com/vitejs/vite/issues/352#issuecomment-639538358
 */
import $ from 'jquery';

// Don't require jQuery if already defined as any registered plugins will be lost!
// setting this here to ensure local dev picks up jquery instance
if (!window.jQuery && !window.$) {
  window.$ = $;
  window.jQuery = $;
}
