export default {
  emojiUnicode: {
    partyPopper: '🎉',
    wave: '👋',
  },

  formattedDateTypes: {
    idsourceLastContactField: 'idsourceLastContactField',
    systemCreated: 'systemCreated',
    systemLastContact: 'systemLastContact',
    userPasswordExpiry: 'userPasswordExpiry',
    userPasswordDate: 'userPasswordDate',
    workdayTimestampField: 'workdayTimestampField',
  },

  mfaTypes: {
    totp: 'totpStatus',
    webAuthn: 'webAuthnStatus',
    push: 'pushStatus',
    overall: 'overallStatus',
  },

  orgDetailTypes: {
    characters: 'characters',
    days: 'days',
    emptyContact: 'not set',
    lowercase: 'lowercase',
    number: 'number',
    uppercase: 'uppercase',
    symbol: 'symbol',
  },

  statusIconTypes: {
    adBridgeAgent: 'adBridgeAgent',
    application: 'application',
    applicationModal: 'applicationModal',
    applicationV2: 'applicationV2',
    system: 'system',
    systemMfa: 'systemMfa',
    systemUserMfa: 'systemUserMfa',
    systemUser: 'systemUser',
    policyStatus: 'policyStatus',
    user: 'user',
  },
};
