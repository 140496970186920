export default {
  userConsoleRootRoute: '/userconsole',
  /**
   * Type of alert
   */
  alertTypes: {
    error: 'error',
    success: 'success',
  },

  loginRoutes: {
    admin: '/login/admin',
    user: '/login',
  },

  authURLs: {
    admin: '/auth',
    user: '/userconsole/auth',
  },

  /**
   * Names of login button types
   */
  buttonTypes: {
    chooseOS: 'chooseOS',
    error: 'error',
    success: 'success',
  },

  /**
   * Names of the different client types
   */
  clientType: {
    admin: 'admin',
    user: 'user',
  },

  /**
   * Name of cookie that determines whether the user or admin page is displayed
   */
  clientTypeCookie: 'jc_prevLoginType',

  errorParamTypes: {
    googleLoginError: 'googleLoginError',
    pushDenied: 'pushDenied',
  },

  formElements: {
    formRef: 'formComponent',
    confirmPassword: {
      vvName: 'confirm password',
      errorMessage: 'Passwords do not match.',
    },
  },

  googleTrackingEvent: { event: 'new-google-signup' },

  oauth: 'oauth',

  redirectTo: 'redirectTo',

  /**
   * Messages that the UI displays after responses are received
   */
  responseMessages: {
    adminGoogleRegistrationError: 'Error occurred while creating your account.',
    invalidCsrf: 'ForbiddenError: invalid csrf token',
    loginError: 'Authentication failed.',
    mfaRequired: 'MFA required.',
    passwordResetError: 'Password reset could not be completed at this time.',
    userRegistrationError: 'Registration could not be completed at this time.',
    invalidOtpError: 'Verification code was invalid or already used.',
  },

  routeGeneratorError: 'RouteGenerator was passed empty argument(s)',

  sso: 'sso',
};
