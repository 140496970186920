export default {
  views: {
    selection: 'selection',
    results: 'results',
    options: 'options',
  },

  defaultMaxUsersDisclaimer: 'Keep in mind that your first 10 users are free. Adding another user moves your organization out of being a free account. Explore pricing options to figure out which plan could work for you.',

  csvEventTypes: {
    error: 'error',
    header: 'header',
  },

  csvImportTemplate: [
    {
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
    },
  ],

  exceed11UsersWarning: {
    whileCustomer: 'Importing users will increase your payment high-water mark. Don’t import users that you don’t intend to manage with JumpCloud.',
    whileVfa: 'Exceeding 11 users will require you to purchase a subscription. Don’t import users that you don’t intend to manage with JumpCloud.',
  },

  importErrors: {
    missingColumn: 'A required column is missing from the CSV file.',
    noUsersToImport: 'There were no users to import. This could be due to an error in the CSV file format.',
    unableToImportUsers: 'An error occurred while importing users from the CSV file.',
  },

  importResultStatus: {
    canceled: 'canceled',
    errored: 'errored',
    finished: 'finished',
    inProgress: 'in progress',
    pending: 'pending',
    started: 'started',
    success: 'Success: User Imported',
  },

  requiredColumns: [
    'email',
    'firstname',
    'lastname',
    'password',
    'username',
  ],

  scimUserAdditionalAttributes: [
    'costCenter',
    'employeeType',
    'employeeIdentifier',
    'company',
  ],

  customRestAdditionalAttributes: [
    'alternateEmail',
    'department',
    'jobTitle',
    'location',
    'manager',
  ],

  scimUsersImportSubHeaderTexts: {
    bulkResultsHeaderSubtext: "Your import was quite large. We will email the results of your import when it's completed.",
    resultsHeaderSubtext: 'These are the results of your import. Continue to onboard these users through the following options.',
    selectionHeaderSubtext: 'Select the users you want to import from this application into JumpCloud.',
  },

  /**
   * Array with the keys used to identify user type
   * Examples:
   * primaryEmail is the unique property that a Google user has
   * userPrincipalName is the unique property that an M365 user has
   */
  searchProperties: [
    'email',
    'primaryEmail',
    'userPrincipalName',
  ],

  source: {
    default: 'jumpcloud:bulk',
    gapps: 'jumpcloud:gapps',
    o365: 'jumpcloud:o365',
    workday: 'jumpcloud:workday',
    scim: 'jumpcloud:scim',
    custom: 'jumpcloud:custom_integration',
  },

  sourceHeader: 'creation-source',
  sourceNameHeader: 'creation-source-name',

  csvResults: {
    success: 'Click the download button below to view the results of your import',
    gone: 'Import Job data is no longer available',
    notFound: 'Import Job not found',
    genericError: 'No data available for download',
  },

  gSuiteHeaderTexts: {
    bulkResultsHeaderText: 'Import in Progress',
    resultsHeaderText: 'Import Complete',
    selectionHeaderText: 'Import New Users from Google',
    optionsHeaderText: 'Import New Users and Update Existing Users from Google',
  },

  gSuiteImportUsersTexts: (defaultState) => ({
    bulkResultsHeaderSubtext: '',
    resultsHeaderSubtext: 'Users must be members of a user group bound to Google for JumpCloud to manage their identity and/or access to Google.',
    selectionHeaderSubtext: `Select the new users you want to import from Google into JumpCloud. All users in your organization will be imported as ${defaultState} according to the Default User State for Creation setting, except for suspended users who will remain suspended.`,
    optionsHeaderSubtext: `Select the new users you want to import and/or the already imported users you want to update from Google into JumpCloud. All users in your organization will be imported as ${defaultState} according to the Default User State for Creation setting, except for suspended users who will remain suspended.`,
  }),
};
