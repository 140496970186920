const pageSections = {
  systemInsights: 'SystemInsights',
};

const filters = {
  filters: 'interfaceDetails',
};

const navigation = {
  deviceGroups: 'Device Groups',
  policies: 'Policies',
  policiesResults: 'Policy Results',
  details: 'Details',
  devices: 'Devices',
  userGroups: 'User Groups',
  sections: pageSections,
  filters,
};

const NoDeviceWidgetText = {
  text: 'There\'s nothing here yet! Add devices to JumpCloud to access Device Notifications',
  title: 'Device Notifications',
  buttonText: 'Add Device',
};

const filterSettingsPolicies = {
  linuxSshServerConfig: 'linux_cis_2_0_0_ssh_root_access',
};

export default {
  navigation,
  NoDeviceWidgetText,
  filterSettingsPolicies,
};
